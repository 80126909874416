// HomePage.tsx
import React, { useState } from 'react';
import styles from './Contact.module.scss';
import { useContactMutation } from '../../state/api';

function ContactPage() {
  
  const [sendEmail] = useContactMutation();
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
  
    try {
      sendEmail(formValues).then((res) => {
        //@ts-ignore
        if (res.data.message==="Email sent successfully")
          alert('Contact form submitted.'); // Placeholder action
        else 
          alert('Error submitting contact form.'); // Placeholder action
      });
    } catch (e) {
      alert('Error submitting contact form.'); // Placeholder action
    }
  };

  return (
    <div className="content-container content-main-container">
      <div className={styles.contactContainer}>
        <div className={styles.contact}>
          <div className={styles.header}>
            <div className={styles.title}>Contact Us</div>
            <div className={styles.subTitle}>If you have an enquiry, or if you’d like help elevating your casino to the next level, get in touch.</div>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="name">Name <span>*</span></label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email <span>*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message">Message <span>*</span></label>
              <textarea
                id="message"
                name="message"
                value={formValues.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;

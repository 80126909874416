import { Logo } from "./Logo";
import styles from "./ProgramCTA.module.scss";

export function ProgramCTA() {
  return (
    <div className={styles.programContainer}>
      <div className={styles.programContent}>
        <div className={styles.programTitle}>
          The Tanzanite Standard
        </div>
        <div className={styles.programDescription}>
          <p>Are you an influencer and do you value transparancy for your viewers?</p> 
          <p>Apply today and become a part of the Tanzanite Standard.</p>
        </div>
   
        <a href={"https://forms.gle/YFuHcLkjGXx7w9QN7"} target="_blank" rel="noopener noreferrer">
          <div className={styles.programButton}>
            Join the program
          </div>
        </a>
      </div>
      <div className={styles.programIcon}>
        <Logo size={124}/>
      </div>
    </div>
  )
}
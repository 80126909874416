import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryLegend, VictoryLine, VictoryStack } from 'victory';
import styles from './LineChart.module.scss';
import analyticsStyles from '../Analytics.module.scss';
import { DashboardData, DataBlock_Clean, EntityData } from '../../../models/DashboardData';
import { useState } from 'react';
import { FullLogo } from '../../../components/Logo';
import { ToSmallNumber } from '../Utility';
import SectionHeader from './SectionHeader';

function LineChart({dashboardData, selectedCasinos, timeframe, selectedChains}: {dashboardData: DashboardData | undefined, selectedCasinos: string[], timeframe: string, selectedChains: string[] }) {

  const [chartDataType, setChartDataType] = useState('depositVolume');
  let casinoData = [];

  if (!(dashboardData === undefined || JSON.stringify(dashboardData) === "{}")) {
    for (const entityId of selectedCasinos) {
      const entity = dashboardData.entities.find((entity: EntityData) => entity.id === entityId);
      if (!entity) continue;

      const aggChain = entity.chains.find((chain: any) => chain.chain === "AGG");
      if (!aggChain) continue;

      //@ts-ignore
      const timeframeData: ChainTimeframeData = aggChain[timeframe];

      const newDataPoints = timeframeData.dataBlocks.map((dataBlock: DataBlock_Clean) => ({
        x: new Date(dataBlock.timestamp),
        y: chartDataType == "depositVolume" ? dataBlock.analytics.depositedUsd : chartDataType == "deposits" ? dataBlock.analytics.deposits : dataBlock.analytics.uniqueDepositors,
        casinoName: entityId, // Add the casino name to each data point
      }));
  
      // Calculate the sum of point.y values for the current casino's data
      const sumOfY = newDataPoints.reduce((acc: any, curr: any) => acc + curr.y, 0);
  
      casinoData.push({
        id: entityId,
        name: entity.name,
        color: entity.color,
        data: newDataPoints,
        sumOfY: sumOfY, // Add the sum to the casino data for sorting purposes
      });
    }
  
    // Sort the casinoData array by the sumOfY in descending order
    // If you prefer ascending order, swap 'b.sumOfY' and 'a.sumOfY'
    casinoData.sort((a, b) => a.sumOfY - b.sumOfY);
  
    // Optionally, if you don't need the sumOfY in the final objects, you can remove it
    casinoData.forEach(casino => delete casino.sumOfY);
  }
  
  const formatTick = (tick: any) => {
    const prefix = chartDataType === "depositVolume" ? "$" : "";
    return prefix + ToSmallNumber(tick);
  };

  return (
    <div className={styles.lineChartSection}>
      <SectionHeader 
        title={chartDataType === "uniqueDepositors" ? "New Depositors" : chartDataType === "deposits" ? "Deposits" : "Deposit Volume"} 
        data={dashboardData}
        timeframe={timeframe}
        chains={selectedChains}
      />
      <div className={styles.chartKey} style={{backgroundColor: "transparent"}}>
        {casinoData.sort(x => x.sumOfY).reverse().map((casinoData) => {
          return (
            <div key={casinoData.id} className={styles.keyItem}>
              <div className={styles.keyColor} style={{backgroundColor: casinoData.color}}></div>
              <div className={styles.keyText}>{casinoData.name}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.chart} style={{backgroundColor: "transparent"}}>
        <div className={styles.watermark}>
          {/* <div className={styles.desktop}><Logo size={22} /></div> */}
          <div className={styles.mobile}><FullLogo size={16} /></div>
        </div>
          <VictoryChart
            scale={{ x: "time" }}
            containerComponent={<VictoryContainer style={{ width: "100%", height: "100%" }} />}
            padding={{ top: 0, bottom: 25, left: 67, right: 2 }}
            width={1024}
            height={500}
          >
            <VictoryAxis dependentAxis style={{ grid: { stroke: '#ccc', strokeWidth: 0.5, opacity: 0.2 }, tickLabels: { fill: "transparent" } }} />
            {casinoData.map(casino => (
              <VictoryLine
                key={casino.id}
                data={casino.data}
                style={{ data: { stroke: casino.color, opacity: 0.7 } }}
              />
            ))}
            <VictoryAxis domain={[0, Math.max(...casinoData.flatMap(casino => casino.data.map((point: any) => point.y)))]}  dependentAxis tickFormat={formatTick} style={{ axis: { stroke: '#aaa'}, tickLabels: { fill: "#aaa", fontFamily: "Roboto Mono, monospace" } }} />
            <VictoryAxis fixLabelOverlap style={{ axis: { stroke: '#aaa'}, tickLabels: { fill: "#aaa", fontFamily: "Roboto Mono, monospace" } }} />
          </VictoryChart>
      </div>
      <div className={styles.chartControls} style={{backgroundColor: "transparent"}}>
        <div className={`${styles.chartDataButton} ${chartDataType === "uniqueDepositors" ? styles.active : ''}`} onClick={() => setChartDataType("uniqueDepositors")}>New Depositors</div>
        <div className={`${styles.chartDataButton} ${chartDataType === "deposits" ? styles.active : ''}`} onClick={() => setChartDataType("deposits")}>Deposits</div>
        <div className={`${styles.chartDataButton} ${chartDataType === "depositVolume" ? styles.active : ''}`} onClick={() => setChartDataType("depositVolume")}>Deposit Volume</div>
      </div>
    </div>
  );
}

export default LineChart;
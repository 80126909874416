// HomePage.tsx
import React, { useState } from 'react';
import styles from './AuditsPage.module.scss';
import { useGetReviewQuery } from '../../state/api';
import { CasinoCategory, CasinoReviewData } from '../../models/ReviewData';
import { NavLink } from 'react-router-dom';

function AuditsPageHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <span>Audits</span>
        <div className={styles.betaTag}>Beta</div>
      </div>
      <div className={styles.subTitle}>
        <p>Casino audits with a focus on compliance, fairness and safety. Not overall truthworthiness or quality.</p>
      </div>
    </div>
  )
}

function ScoringButton() {
  return (
    <div className={styles.scoringContainer}>
       <NavLink to={"/score-calculator"}>
        <div className={styles.scoringButton}>          
          Score Calculator
        </div>
      </NavLink>
    </div>
  );
}

function ScoreToColor(score: number) {
  if (score >= 0.75) return "#00BD57";
  if (score >= 0.6) return "#97BD00";
  if (score >= 0.4) return "#C99101";
  if (score >= 0.2) return "#E76203";
  if (score >= 0) return "#E73629";
}

function ScoreBox({object, useColor = false, large = false}: {object: any, useColor?: boolean, large?: boolean}) {
  const boxStyles = {
    ...(useColor ? {
      backgroundColor: ScoreToColor(object.score / object.maxScore),
      ...(large ? { padding: "4px 7px", fontWeight: 600 } : {})
    } : (large ? { padding: "4px 7px", fontWeight: 600 } : {}))
  };
  
  return (
    <div>
      <div className={styles.scoreBox} style={boxStyles} >
          {object.score} / {object.maxScore}
      </div>
    </div>
  );
}

function AuditTableRow({index, name, data}: {index: any, name: string, data: CasinoReviewData}) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  function TableRowGap() {
    return (
      <tr className={styles.tableGap}>
        <td colSpan={100}></td>
      </tr>
    );
  }

  function TableRowHeader() {
    return (
      <tr className={`${styles.tableRow} ${styles.tableRowHoverable}`} onClick={toggleDropdown}>
        <td className={`${styles.tableDataLeft} ${styles.tableDataStart} ${isDropdownVisible ? styles.tableDataDropped : ''}`}>{data.rank}</td>
        <td className={styles.tableDataLeft}>
          <div className={styles.casinoIcon}>
            <img width="18px" height="18px" src={(data["Info"] as CasinoCategory).children["Icon URL"].text}/>
            <span>{name}</span>
          </div>
        </td>
        <td className={`${styles.tableDataRight}`}><ScoreBox object={data} useColor={true} large={true}/></td>
        <td className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
          {!isDropdownVisible && <ScoreBox object={data["Compliance"]}/>}
        </td>
        <td className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
          {!isDropdownVisible && <ScoreBox object={data["Responsible Gambling"]}/>}
        </td>
        <td className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
          {!isDropdownVisible && <ScoreBox object={data["Security Measures"]}/> }
        </td>
        <td className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
          {!isDropdownVisible && <ScoreBox object={data["Support"]}/>}
        </td>
        <td className={`${styles.tableDataRight} ${styles.tableDataEnd} ${isDropdownVisible ? styles.tableDataDropped : ''}`}>
          <div className={styles.dropdownButton} onClick={toggleDropdown}>
            <svg style={{ transform: `rotate(${isDropdownVisible ? 180: 0}deg)` }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M0 5C0 2.23858 2.23858 0 5 0H13C15.7614 0 18 2.23858 18 5V13C18 15.7614 15.7614 18 13 18H5C2.23858 18 0 15.7614 0 13V5Z" fill="#212635"/>
              <path d="M9 12.5L5.5359 6.5L12.4641 6.5L9 12.5Z" fill="#D9D9D9"/>
            </svg>
          </div>
        </td>
      </tr>
    );
  }

  function RowSection({title, object}: {title: string, object: CasinoCategory}) {
    return (
      <div className={styles.rowSection}>
        <table>
          <tr>
            <td className={styles.rowSectionTitle}>{title}</td>
            <td>{object.maxScore !== 0 && <td><ScoreBox object={object} useColor={true}/></td>}</td>
          </tr>
          {Object.entries(object.children)
            .filter(([key, value]) => key !== 'Icon URL') 
            .filter(([key, value]) => key !== 'Description') 
            .filter(([key, value]) => key !== 'Company Name') 
            .map(([key, value]) => (
            <tr key={key}>
              <td className={styles.rowPropertyName}>
                {key}
              </td>
              <td className={styles.rowPropertyValue}>
                {key === "Logs" ? (
                  <a className={styles.logs} href={value.text} target="_blank" rel="noopener noreferrer">Link</a>
                ) : (
                  value.text?.length > 0 ? value.text : value.score + " Points"
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>
    )
  }

  function TableRowContent() {
    return (
      <tr className={styles.tableRowContent}>
        <td colSpan={100}>
          <div className={styles.tableRowContentContainer}>
            <RowSection title={"Casino Information"} object={data["Info"] as CasinoCategory} />
            <div className={styles.rowSectionSeperator}></div>
            <RowSection title={"Compliance"} object={data["Compliance"] as CasinoCategory} />
            <div className={styles.rowSectionSeperator}></div>
            <RowSection title={"Responsible Gambling"} object={data["Responsible Gambling"] as CasinoCategory} />
            <div className={styles.rowSectionSeperator}></div>
            <RowSection title={"Security Measures"} object={data["Security Measures"] as CasinoCategory} />
            <div className={styles.rowSectionSeperator}></div>
            <RowSection title={"Support"} object={data["Support"] as CasinoCategory} />
          </div>
        </td>
      </tr>
    );  
  }

  return (
    <>
      <TableRowGap />
      <TableRowHeader />
      {isDropdownVisible && <TableRowContent />}
    </>
  );
}

function AuditTable() {
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortProperty, setSortProperty] = useState('Score');

  const { data } = useGetReviewQuery();

  const sortData = (property: string) => {
    if (sortProperty === property) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortProperty(property);
      setSortOrder('desc');
    }
  };

  const SortIcon = ({property} : {property: string}) => {
    if (sortProperty === property) {
      return sortOrder === 'desc' 
        ? (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></g></svg>)
        : (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></g></svg>);
    }
    return (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></g></svg>);
  }

  const SortableHeader = ({name} : {name: string}) => {
    return (
      <div>
        <div>
          <div style={{cursor: 'pointer'}} onClick={() => {sortData(name)}}>
            <SortIcon property={name}></SortIcon>
            <span style={{marginLeft: "3px"}}>{name}</span>
          </div>

        </div>
      </div>
    )
  }

  return (
    <>
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableRow}>
              <th className={`${styles.tableDataLeft} ${styles.tableDataStart}`}>
                #
              </th>
              <th className={styles.tableDataLeft}>
                Casino
              </th>
              <th className={`${styles.tableDataRight}`}>
                <SortableHeader name="Score" />
              </th>
              <th className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
                <SortableHeader name="Compliance" />
              </th>
              <th className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
                <SortableHeader name="Responsible Gambling" />
              </th>
              <th className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
                <SortableHeader name="Security Measures" />
              </th>
              <th className={`${styles.tableDataRight} ${styles.hideOnMobile}`}>
                <SortableHeader name="Support" />
              </th>
              <th className={`${styles.tableDataRight} ${styles.tableDataEnd}`}>

              </th>
            </tr>
        </thead>
        <tbody>
          {data && data.casinos && Object.entries(data.casinos)
            .sort(([keyA, a], [keyB, b]) => {
              if (sortProperty === "Score") {
                return sortOrder === 'asc' ? a.score - b.score : b.score - a.score;
              } else {
                //@ts-ignore
                return sortOrder === 'asc' ? a[sortProperty].score - b[sortProperty].score : b[sortProperty].score - a[sortProperty].score;
              }
            })
            .map(([key, datum], index) => {
              return <AuditTableRow key={key} name={key} index={index} data={datum} />;
            })
          }
        </tbody>
      </table>
    </div>
    </>
  )
}

function AuditsPage() {
  
  return (
    <div className="content-container content-main-container ">
      <div className={styles.content}>
        <AuditsPageHeader />
        <ScoringButton />
        <AuditTable />
      </div>
    </div>
  );
}

export default AuditsPage;

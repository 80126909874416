import React, { useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels'; // Optional: for additional data labeling
import { KickSnapshotData } from '../../models/StreamData';
import styles from './StreamChart.module.scss';
import { MdWarning } from 'react-icons/md';
import { FullLogo } from '../../components/Logo';

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);


interface ChartProps {
  data: KickSnapshotData[];
}

const MissingData = ({missing}: {missing: boolean}) => {
  if (missing) {
    return (
      <div style={{marginBottom: "12px", display: "flex", alignItems: "center"}}>
        <MdWarning style={{ color: '#FFC107', fontSize: '24px', marginRight: "5px" }} />
        Missing Data
      </div>
    )
  } else {
    return (
      <></>
    )
  }
} 

const StreamOverview = ({ data }: {data: KickSnapshotData[]}) => {
  if (data.length === 0) return <>No Data</>

  const totalViewers = data.reduce((acc, snapshot) => acc + snapshot.viewers, 0);
  const averageViewers = totalViewers / data.length;

  // 2. Calculate Peak (Maximum) Viewers
  const peakViewers = data.reduce((max, snapshot) => snapshot.viewers > max ? snapshot.viewers : max, 0);

  // 3. Calculate Average Messages per Minute
  let totalMessages = 0;
  let totalTimeMinutes = 0;

  for (let i = 1; i < data.length; i++) {
    const prev = data[i - 1];
    const current = data[i];

    const messagesDiff = current.totalMessages - prev.totalMessages;

    // Ensure that the messages difference is not negative
    const validMessagesDiff = messagesDiff >= 0 ? messagesDiff : 0;

    // Parse timestamps to milliseconds
    const prevTime = new Date(prev.timestamp).getTime();
    const currentTime = new Date(current.timestamp).getTime();

    const timeDiffMs = currentTime - prevTime;
    const timeDiffMinutes = timeDiffMs / (1000 * 60); // Convert milliseconds to minutes

    // Accumulate total messages and total time
    totalMessages += validMessagesDiff;
    totalTimeMinutes += timeDiffMinutes;
  }

  // Handle division by zero if all snapshots have the same timestamp
  const averageMessagesPerMinute = totalTimeMinutes > 0 ? totalMessages / totalTimeMinutes : 0;

  // 4. Calculate Ratio of Average Viewers to Average Messages per Minute
  const viewerToMessageRatio = averageMessagesPerMinute > 0 ? averageViewers / averageMessagesPerMinute : 0;

  return (
    <div className={styles.analyticsItems}>
      <div className={styles.analyticsItem}>
        <div className={styles.analyticsTitle}>
          Peak Viewers  
        </div>
        <div className={styles.analyticsNumber}>
          {peakViewers}
        </div>
      </div>
      <div className={styles.analyticsItem}>
        <div className={styles.analyticsTitle}>
          Avg Viewers  
        </div>
        <div className={styles.analyticsNumber}>
          {averageViewers.toFixed(1)}
        </div>
      </div>
      <div className={styles.analyticsItem}>
        <div className={styles.analyticsTitle}>
          Avg Msg Per Min  
        </div>
        <div className={styles.analyticsNumber}>
          {averageMessagesPerMinute.toFixed(1)}
        </div>
      </div>
      <div className={styles.analyticsItem}>
        <div className={styles.analyticsTitle}>
          Avg Viewer To Msg Ratio
        </div>
        <div className={styles.analyticsNumber}>
          {viewerToMessageRatio.toFixed(1)}
        </div>
      </div>
    </div>
  )
}

const StreamChart: React.FC<ChartProps> = ({ data: _data }) => {

  const data = _data.filter(x => x.viewers !== null && x.followers !== null)


  // 1. Initialize state for toggling datasets (if needed)
  // In this implementation, Chart.js handles legend interactions internally,
  // so additional state management isn't necessary. However, if you want
  // custom interactions, you can implement them here.

  // 2. Sort data by timestamp ascending
  const sortedData = useMemo(() => {
    return [...data].sort(
      (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
  }, [data]);

  // 3. Process data to calculate required metrics
  const processedData = useMemo(() => {
    if (sortedData.length === 0) return [];

    const startTime = new Date(sortedData[0].timestamp).getTime();
    const result: Array<{
      timeSinceStart: number; // in minutes
      followersGained: number;
      messages: number;
      viewers: number;
      uniqueMessagers: number;
    }> = [];

    for (let i = 0; i < sortedData.length; i++) {
      const current = sortedData[i];
      const currentTime = new Date(current.timestamp).getTime();
      const timeSinceStart = (currentTime - startTime) / (1000 * 60); // in minutes
      const followersGained = current.followers - sortedData[0].followers;

      result.push({
        timeSinceStart: timeSinceStart,
        followersGained,
        messages: Math.floor(current.totalMessages / 10),
        viewers: current.viewers,
        uniqueMessagers: current.uniqueMessagers
      });
    }

    return result;
  }, [sortedData]);

  // 4. Prepare labels (time since start in minutes)
  const labels = useMemo(() => {
    return processedData.map((d) => `${Math.round(d.timeSinceStart)}m`);
  }, [processedData]);

  // 5. Define datasets
  const commonPointStyles = {
    pointRadius: 1,
    pointHoverRadius: 5,
  };

  const datasets = useMemo<ChartData<'line'>['datasets']>(() => {
    return [
      {
        label: 'Followers Gained',
        data: processedData.map((d) => d.followersGained),
        borderColor: '#00bfff',
        backgroundColor: '#00bfff',
        fill: false,
        tension: 0.4,
        yAxisID: 'y',
        ...commonPointStyles
      },
      {
        label: 'Messages / 10',
        data: processedData.map((d) => d.messages),
        borderColor: '#32cd32',
        backgroundColor: '#32cd32',
        fill: false,
        tension: 0.4,
        yAxisID: 'y',
        ...commonPointStyles
      },
      {
        label: 'Viewers',
        data: processedData.map((d) => d.viewers),
        borderColor: '#ff6347',
        backgroundColor: '#ff6347',
        fill: false,
        tension: 0.4,
        yAxisID: 'y',
        ...commonPointStyles
      },
      {
        label: 'Unique Messagers',
        data: processedData.map((d) => d.uniqueMessagers),
        borderColor: '#ffa500',
        backgroundColor: '#ffa500',
        fill: false,
        tension: 0.4,
        yAxisID: 'y',
        ...commonPointStyles
      },
    ];
  }, [processedData]);

  // 6. Chart data
  const chartData: ChartData<'line'> = {
    labels,
    datasets,
  };

  // 7. Chart options
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false, // Allows custom height via container
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    layout: {
      padding: {
        top: -15, // Dynamic padding
        left: -10
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        
        labels: {
          font: {
            size: 12,
            family: 'Inter, sans-serif',
          },
          color: '#aaa',
          boxWidth: 10, // Sets the width of the colored box to 12px
          boxHeight: 10, // Sets the height of the colored box to 12px
          padding: 20, // Adds 20px padding between each legend item
          
        },
        
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          if (typeof index === 'number') {
            const ci = legend.chart;
            const meta = ci.getDatasetMeta(index);
            // Toggle dataset visibility between true and false
            meta.hidden = !meta.hidden;
            ci.update();
          }
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index' as const,
        intersect: false,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            if (label.includes('Followers')) {
              return `Followers Gained: ${value}`;
            } else if (label.includes('Messages')) {
              return `Messages / 10: ${value}`;
            } else if (label.includes('Viewers')) {
              return `Viewers: ${value}`;
            } else if (label.includes('Unique Messagers')) {
              return `Unique Messagers: ${value}`;
            }
            return `${label}: ${value}`;
          },
        },
        backgroundColor: '#111525AA',
        padding: 10,
        titleFont: {
          size: 16,
          family: 'Inter, sans-serif',
        },
        bodyFont: {
          size: 14,
          family: 'Inter, sans-serif',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time Since Start (minutes)',
          font: {
            size: 12,
            family: 'Roboto Mono, monospace',
          },
          color: '#aaa',
        },
        ticks: {
          font: {
            size: 12,
            family: 'Roboto Mono, monospace',
          },
          color: '#aaa',
          stepSize: 5, // Increment by 5 minutes
          callback: function (tickValue: string | number, index: number, ticks: any[]) {
            if (typeof tickValue === 'number') {
              return `${tickValue}m`; // Append 'm' to each label
            }
            return tickValue;
          },
        },
        
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          font: {
            size: 12,
            family: 'Roboto Mono, monospace',
          },
          color: '#aaa',
        },
        ticks: {
          font: {
            size: 12,
            family: 'Roboto Mono, monospace',
          },
          color: '#aaa',
        },
      }
    }
  };

  // 8. Render the chart or a message based on data availability
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
       
        display: 'flex',
        flexDirection: 'column',
        gap: "10px"
      }}
    >
      {processedData.length === 0 ? (<>
        <MissingData missing={true}/>
        <div>No data available</div></>
      ) : (
        <>
          <MissingData missing={data.length < _data.length || data.length === 0}/>
          <StreamOverview data={data} />
          <div className={styles.chartContainer}>
            <div className={styles.watermark}><FullLogo size={20}/></div>
            <Line data={chartData} options={options} />
          </div>
        </>
      )}
    </div>
  );
};

export default StreamChart;

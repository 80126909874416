import { useMemo, useState } from "react";
import { FiSearch } from 'react-icons/fi'; // Import the search icon
import { Creator, CreatorData, Icons } from "../../models/CreatorsData";
import styles from './CasinoTable.module.scss';
import { Badge } from "../../components/Badge";
import { NavLink } from "react-router-dom";

interface CasinoSummary {
  name: string;
  streamers: number;
  level1: number;
  level2: number;
  total: number;
  percentage: number;
}

function CasinoTableRow({casino, icons}: {casino: CasinoSummary, icons: Icons}) {

  function TableRowGap() {
    return (
      <tr className={styles.tableGap}>
        <td colSpan={100}></td>
      </tr>
    );
  }

  function TableRow() {
    return (
      <tr className={`${styles.tableRow}`}>
        <td className={`${styles.tableDataLeft} ${styles.tableDataStart}`}>
          <div className={styles.creatorContainer}>
            <img width="18px" height="18px" src={icons[casino.name]}/>
            <span>{casino.name}</span>
          </div>
        </td>

        <td className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
          {casino.streamers?.toLocaleString() ?? "0"}
        </td>

        <td className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
          {casino.level1?.toLocaleString() ?? "0"}
        </td>

        <td className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
          {casino.level2?.toLocaleString() ?? "0"}
        </td>

        <td className={`${styles.tableDataCenter}`}>
          {Math.round((casino.percentage ?? 0) * 100) + "%"}
        </td>

        <td className={`${styles.tableDataRight} ${styles.tableDataEnd}`}>
          {casino.total?.toLocaleString() ?? "0"}
        </td>
      </tr>
    );
  }

  return (
    <>
      <TableRowGap />
      <TableRow />
    </>
  );
}

export function CasinoTable({data}: {data: CreatorData | undefined}) {
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortProperty, setSortProperty] = useState('Total Points');

  const sortData = (property: string) => {
    if (sortProperty === property) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortProperty(property);
      setSortOrder('desc');
    }
  };

  const SortIcon = ({property} : {property: string}) => {
    if (sortProperty === property) {
      return sortOrder === 'desc' 
        ? (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></g></svg>)
        : (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></g></svg>);
    }
    return (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></g></svg>);
  }

  const SortableHeader = ({name} : {name: string}) => {
    return (
      <div>
        <div>
          <div style={{cursor: 'pointer'}} onClick={() => {sortData(name)}}>
            <SortIcon property={name}></SortIcon>
            {name === "Rank" ? <span className={styles.hideOnMobile} style={{marginLeft: "3px"}}>{name}</span> : <span style={{marginLeft: "3px"}}>{name}</span>}
          </div>

        </div>
      </div>
    )
  }

  const casinoSummaries = categorizeByCasino(data?.creators ?? []);

  const sortedCasinos = casinoSummaries.sort((a: CasinoSummary, b: CasinoSummary) => {
      if (sortProperty === 'Creator Count') {
        return sortOrder === 'asc' ? a.streamers - b.streamers : b.streamers - a.streamers;
      }
      if (sortProperty === 'Level 1 Verified') {
        return sortOrder === 'asc' ? a.level1 - b.level1 : b.level1 - a.level1;
      }
      if (sortProperty === 'Level 2 Verified') {
        return sortOrder === 'asc' ? a.level2 - b.level2 : b.level2 - a.level2;
      }
      if (sortProperty === 'Total Points') {
        return sortOrder === 'asc' ? a.total - b.total : b.total - a.total;
      }
      if (sortProperty === 'Verified %') {
        return sortOrder === 'asc' ? a.total - b.total : b.total - a.total;
      }

      return 1;
  });



  return (
    <>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tableRow} ${styles.tableRowHeader}`}>
                <th className={`${styles.tableDataLeft} ${styles.tableDataStart}`}>
                  Casino
                </th>
                <th className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
                  <SortableHeader name="Creator Count" />
                </th>
                <th className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
                  <SortableHeader name="Level 1 Verified" />
                </th>
                <th className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
                  <SortableHeader name="Level 2 Verified" />
                </th>
                <th className={`${styles.tableDataCenter}`}>
                  <SortableHeader name="Verified %" />
                </th>
                <th className={`${styles.tableDataRight} ${styles.tableDataEnd}`}>
                  <SortableHeader name="Total Points" />
                </th>
              </tr>
          </thead>
          <tbody>
          {data && sortedCasinos
            .map((datum, index) => {
              return <CasinoTableRow key={index} icons={data.icons} casino={datum}/>;
            })
          }
          </tbody>
        </table>
      </div>
    </> 
  )
}

function categorizeByCasino(creators: Creator[]): CasinoSummary[] {
  // Map to hold casino data
  const casinoMap: Record<
      string,
      {
          creators: Record<string, number>;
      }
  > = {};

  for (const creator of creators) {
      const { name, deals } = creator;

      for (const deal of deals) {
          const { casino, verificationLevel } = deal;


          if (!casinoMap[casino]) {
              casinoMap[casino] = { creators: {} };
          }

          const casinoData = casinoMap[casino];

          if (!casinoData.creators[name] || casinoData.creators[name] < verificationLevel) {
              casinoData.creators[name] = verificationLevel;
          }
      }
  }

  const casinoSummaries: CasinoSummary[] = [];

  for (const casinoName in casinoMap) {
      const casinoData = casinoMap[casinoName];
      const levelCounts: Record<number, number> = {};

      for (const creatorName in casinoData.creators) {
          const level = casinoData.creators[creatorName];
          levelCounts[level] = (levelCounts[level] || 0) + 1;
      }

      const summary: CasinoSummary = {
          name: casinoName,
          streamers: Object.keys(casinoData.creators).length,
          level1: levelCounts[1] || 0,
          level2: levelCounts[2] || 0,
          total: (levelCounts[1] || 0) + (levelCounts[2] || 0) * 2,
          percentage: ((levelCounts[1] || 0) + (levelCounts[2] || 0)) / Object.keys(casinoData.creators).length
      };

      casinoSummaries.push(summary);
  }

  return casinoSummaries;
}

// import { VictoryLegend, VictoryPie } from 'victory';
import { ChainTimeframeData, DashboardData, EntityData } from '../../../models/DashboardData';
import { ToSmallNumber } from '../Utility';
import styles from './MarketShare.module.scss';
import tableStyles from "./Tables.module.scss";
import analyticsStyles from '../Analytics.module.scss';
import SectionHeader from './SectionHeader';

// function MarketShareSection({dashboardData, timeframe}: {dashboardData: any, timeframe: string}) {
//   const chartData = [];

//   for (const casino of Object.keys(dashboardData)) {
//     if (casino === "all") continue;
//     const deposited = dashboardData[casino][timeframe].totalDeposited;

//     const label = casino.charAt(0).toUpperCase() + casino.slice(1);
//     chartData.push({x: casino, y: deposited})
//   }

//   const legendData = [];

//   for (const casino of Object.keys(dashboardData)) {
//     if (casino === "all") continue;
//     const casinoInfo = casinoConfig[casino];
//     legendData.push({name: casinoInfo.name, symbol: { fill: casinoInfo.color }})
//   }

//   const total = chartData.reduce((acc, data) => acc + data.y, 0);
  
//   return (
//     <div className={styles.marketShareSection}>
//       <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
//         <VictoryLegend y={-5} x={5} height={30} width={510}
//             orientation="horizontal"
//             data={legendData.slice(0, legendData.length / 2)} // First half for the first line
//             gutter={20}
//             style={{
//               labels: { fill: "#aaa", fontFamily: "Roboto Mono, monospace" },
//             }}
//         />

//         <VictoryLegend y={-5} x={5} height={20} width={510} // Adjusted Y position for the second line
//             orientation="horizontal"
//             data={legendData.slice(legendData.length / 2)} // Second half for the second line
//             gutter={20}
//             style={{
//               labels: { fill: "#aaa", fontFamily: "Roboto Mono, monospace" },
//             }}
//         />
//         <VictoryPie
//           width={295}
//           height={220}
//           data={chartData}
//           padding={{ top: 7, bottom: 0, left: 50, right: 50 }}
//           labels={({ datum }) => `${datum.y / total * 100 > 2 ? `${(datum.y / total * 100).toFixed(1)}%` : ""}`}
//           labelRadius={({ innerRadius }) => {
//             const radius = typeof innerRadius === 'function' ? innerRadius({}) : innerRadius || 0;
//             return radius + 107;
//           }}
//           colorScale={chartData.map(datum => casinoConfig[datum.x].color)}
//           style={{
//             data: {
//               fillOpacity: 0.7,
//               stroke: "#fff",
//               strokeWidth: 0
//             },
//             labels: {
//               fontSize: 10,
//               fill: "#aaa",
//               fontFamily: "Roboto Mono, monospace"
//             }
//           }}
//         />
//       </div>
//     </div>
//   );
// }

function MarketShareSection({dashboardData, selectedCasinos, timeframe, selectedChains}: {dashboardData: DashboardData | undefined, selectedCasinos: string[], timeframe: string, selectedChains: string[]}) {
  if (dashboardData === undefined) return <></>;
  
  const data = selectedCasinos.map(casino => {
    const casinoData = dashboardData.entities.find(entity => entity.id === casino) as EntityData

    return {
      id: casinoData.id,
      name: casinoData.name,
      color: casinoData.color,
      iconUrl: casinoData?.iconUrl,
      //@ts-ignore
      data: (casinoData?.chains.find(chain => chain.chain === "AGG")?.[timeframe]) as ChainTimeframeData
    }
  }).sort((a, b) => b.data.analytics.depositedUsd - a.data.analytics.depositedUsd);

  const totalDeposited = data.reduce((acc, casino) => acc + casino.data.analytics.depositedUsd, 0);

  return (
    <div className={styles.marketShareSection}>
      <SectionHeader
        title={"Market Breakdown"}
        data={dashboardData}
        timeframe={timeframe}
        chains={selectedChains}
      />
      <div className={tableStyles.baseTableContainer}>
        <table className={tableStyles.baseTable}>
          <thead>
            <tr>
              <th style={{width: "5%"}} className={`${tableStyles.tableDataLeft} ${tableStyles.tableDataStart}`}>#</th>
              <th style={{width: "5%"}} className={`${tableStyles.tableDataLeft}`}>Casino</th>
              <th style={{width: "12%"}} className={`${tableStyles.tableDataRight}`}>Market Share</th>
              <th style={{width: "15%"}} className={`${tableStyles.tableDataRight}`}>Deposit Volume</th>
              <th style={{width: "12%"}} className={`${tableStyles.tableDataRight}`}>Deposits</th>
              <th style={{width: "20%"}} className={`${tableStyles.tableDataRight} ${tableStyles.tableDataEnd}`}>New Depositors</th>
            </tr>
          </thead>
          <tbody>
            { 
              data.map((casino, index) => {
                console.log(casino);

                const marketShare = (casino.data.analytics.depositedUsd / totalDeposited * 100).toFixed(2) + "%"; // Round to 1 decimal place
                const deposited  = "$" + ToSmallNumber(casino.data.analytics.depositedUsd);
                const deposits = ToSmallNumber(casino.data.analytics.deposits);
                const uniqueDepositors = ToSmallNumber(casino.data.analytics.uniqueDepositors);

                return (
                  <>
                    <tr className={tableStyles.rowGap}></tr>
                    <tr className={tableStyles.rowInfo} key={casino.id}>
                      <td className={`${tableStyles.tableDataLeft} ${tableStyles.tableDataStart}`}>{index + 1}</td>
                      <td className={`${tableStyles.tableDataLeft}`}>
                        <div className={tableStyles.casinoKey}>
                          <img src={casino.iconUrl} alt={`Icon ${casino.name}`} /> 
                          <span>{casino.name}</span>
                        </div>
                      </td>
                      <td className={`${tableStyles.tableDataRight}`}>{marketShare}</td>
                      <td className={`${tableStyles.tableDataRight}`}>{deposited}</td>
                      <td className={`${tableStyles.tableDataRight}`}>{deposits}</td>
                      <td className={`${tableStyles.tableDataRight} ${tableStyles.tableDataEnd}`}>{uniqueDepositors}</td>
                    </tr>
                  </>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MarketShareSection;
import styles from './ScoreCalculatorPage.module.scss';
import { CasinoCategory, CasinoMetric, CasinoReviewData, CasinoSubMetric, InfoCategory, ReviewData } from "../../models/ReviewData";
import { useGetReviewQuery } from '../../state/api';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { MenuItem, Select, TextField, MenuProps as MuiMenuProps } from '@mui/material';
import { NavLink } from 'react-router-dom';

const CustomSelect = styled(Select)({
  width: '100%', // Full width
  height: '40px',
  backgroundColor: '#1C2339', // Red fill
  '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', // No border
  },
  '& .MuiInputLabel-root': {
      color: 'white', // White label text for better contrast on red
  },
  '& .MuiSelect-select': {
      color: 'white', // White text for better contrast on red
      fontFamily: 'Inter', // Custom font (Example: Arial)
      fontWeight: 'bold', // Bold font weight
  },
  '& .MuiSvgIcon-root': { // Add type assertion to fix the error
    color: 'white', // Set the color of the chevron icon
}
});

const StyledMenuProps: Partial<MuiMenuProps> = {
  PaperProps: {
      sx: {
          maxHeight: 300,  // Maximum height of the dropdown
          overflow: 'auto',  // Enable scrollbar when content is larger than max height
          backgroundColor: '#2D395C',  // Background color of the dropdown
          color: 'white',  // Text color
          fontFamily: 'Inter',  // Font family
          fontWeight: 'bold',  // Font weight
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',

          }
      },
  },
};

function ScoreToColor(score: number) {
  if (score >= 0.75) return "#00BD57";
  if (score >= 0.6) return "#97BD00";
  if (score >= 0.4) return "#C99101";
  if (score >= 0.2) return "#E76203";
  if (score >= 0) return "#E73629";
}

function ScoreBox({object, useColor = false, large = false}: {object: any, useColor?: boolean, large?: boolean}) {
  const boxStyles = {
    ...(useColor ? {
      backgroundColor: ScoreToColor(object.score / object.maxScore),
      ...(large ? { padding: "4px 7px", fontWeight: 600 } : {})
    } : (large ? { padding: "4px 7px", fontWeight: 600 } : {}))
  };
  
  return (
    <div>
      <div className={styles.scoreBox} style={boxStyles} >
          {object.score} / {object.maxScore}
      </div>
    </div>
  );
}

function InfoText({ text, description }: { text: string, description: string }) {
  return (
    <div className={styles.infoText}>
      {text.split("(")[0]}
      <span className={styles.infoTextDesc}>
        {description}
      </span>
    </div>
  );
}

function getRelativeRanking(score: number, parentObject: { [key: string]: CasinoReviewData }): number {
    // Extract scores from the parent object
    const scores = Object.values(parentObject).map(obj => obj.score);
    
    // Add the given score to the list of scores
    scores.push(score);
    
    // Sort the scores in descending order
    const sortedScores = scores.sort((a, b) => b - a);
    
    // Find the index of the given score in the sorted array
    const index = sortedScores.indexOf(score);
    
    // Return the ranking (1-based index)
    return index + 1;

}

const ScoreCalculator = ({ data }: { data: ReviewData }) => {
  const [selectedCasino, setSelectedCasino] = useState('Stake');
  const [casinoData, setCasinoData] = useState(cloneDeep(data.casinos[selectedCasino]))

  function updateCasinoDataMetric(value: boolean, categoryKey: string, metricKey: string, subMetricKey: string | null = null) {

    const newData = cloneDeep(casinoData);
    const casinoCategory = newData[categoryKey] as CasinoCategory;
    const casinoMetric = casinoCategory.children[metricKey] as CasinoMetric;

    if (subMetricKey === null) {

      if (casinoMetric.value === value) return;
      casinoMetric.value = value;
      if (value === true) {
        casinoMetric.score = casinoMetric.maxScore;
        newData.score += casinoMetric.maxScore;
        casinoCategory.score += casinoMetric.maxScore;
      } else {
        casinoMetric.score = 0;
        newData.score -= casinoMetric.maxScore;
        casinoCategory.score -= casinoMetric.maxScore;
      }
    } else {
      if (!casinoMetric.children) return;
      const casinoSubMetric = casinoMetric.children[subMetricKey] as CasinoSubMetric;

      if (casinoSubMetric.value === value) return;
      casinoSubMetric.value = value;
      if (value === true) {
        casinoSubMetric.score = casinoSubMetric.maxScore;
        casinoMetric.score += casinoSubMetric.maxScore;
        casinoCategory.score += casinoSubMetric.maxScore;
        newData.score += casinoSubMetric.maxScore;
      } else {
        casinoSubMetric.score = 0;
        casinoMetric.score -= casinoSubMetric.maxScore;
        casinoCategory.score -= casinoSubMetric.maxScore;
        newData.score -= casinoSubMetric.maxScore;
      }
    }

    setCasinoData(newData);
  }

  

  function CategorySection({title, object}: {title: string, object: CasinoCategory}) {
    return (
      <div className={styles.categorySection}>
        <table>
          <tr>
            <td className={styles.rowSectionTitle}>{title}</td>
            <td className={styles.categoryScore}>{object.maxScore !== 0 && <td><ScoreBox object={object} useColor={true}/></td>}</td>
          </tr>
          {Object.entries(object.children)
            .filter(([key, value]) => key != "Logs")
            .map(([key, value]) => (
            <tr key={key}>
              <td className={styles.rowPropertyName}>
                {key}
              </td>
              <td className={styles.rowPropertyValue}>
                {value.text?.length > 0 ? value.text : value.score + " Points"}
              </td>
            </tr>
          ))}
        </table>
      </div>
    )
  }

  function CategorySectionDivider({symbol}: ({symbol: string})) {
    return (
      <div className={styles.categorySectionDivider}>
        <div></div>
        <span>{symbol}</span>
        <div></div>
      </div>
    );
  }

  function CategoryConfiguratorRow({index, categoryKey, metricKey, metricValue, subMetricKey = null, subMetricValue = null}: {index: number, categoryKey: string, metricKey: string, metricValue: CasinoMetric, subMetricKey?: string | null, subMetricValue?: CasinoSubMetric | null}) {
    return (
      <>
        <td>
          {index == 0 && (
            <div className={styles.configuratorRowField}>
              <span><InfoText text={metricKey} description={ data.info[categoryKey].children?.[metricKey].description || "" }/></span>
              <div className={styles.configuratorRowScore}>{metricValue.score} / {metricValue.maxScore}</div>
            </div>
          )}
        </td>
        <td className={styles.configuratorRowGap}>
        </td>
        <td>
          <div className={styles.configuratorRowField}>
            {subMetricKey && (
              <>
                <span><InfoText text={subMetricKey} description={ data.info[categoryKey].children?.[metricKey].children?.[subMetricKey].description || "" }/></span>
                <div className={styles.configuratorRowScore}>{subMetricValue?.score} / {subMetricValue?.maxScore}</div>
              </>
            )}
          </div>
        </td>
        <td className={styles.configuratorRowGap}>
        </td>
        <td>
          <div className={styles.yesNoContainer}>
            <div className={styles.yesNoButton}>
              <div 
                //@ts-ignore
                className={`${styles.yesButton} ${(subMetricKey ? casinoData[categoryKey].children[metricKey].children[subMetricKey].value : casinoData[categoryKey].children[metricKey].value) ? styles.yesNoSelected : ""}`}
                onClick={() => updateCasinoDataMetric(true, categoryKey, metricKey, subMetricKey)}
              >
                Yes
              </div>
              <div 
                //@ts-ignore
                className={`${styles.yesButton} ${(subMetricKey ? casinoData[categoryKey].children[metricKey].children[subMetricKey].value : casinoData[categoryKey].children[metricKey].value) ? "" : styles.yesNoSelected}`}
                onClick={() => updateCasinoDataMetric(false, categoryKey, metricKey, subMetricKey)}
              >
                No
              </div>
            </div>
          </div>
        </td>
      </>
    );

  }

  function CategoryConfigurator({title, object}: {title: string, object: CasinoCategory}) {
    return (
      <>
        <tr>
          <td colSpan={100}>
            {title === "Compliance" ? (
              <div className={styles.topConfigSubTitle}>
                <div className={styles.configuratorSubTitle}>{title}</div>
                <div className={styles.restoreDefaults} onClick={() => {setCasinoData(cloneDeep(data.casinos[selectedCasino]))}}>Restore Defaults</div>
              </div>
            ) : (
              <div className={styles.configuratorSubTitle} style={{paddingTop: "25px"}}>{title}</div>
            )}
          </td>
        </tr>
        {Object.entries(object.children)
          .filter(([key, value]) => key != "Logs")
          .map(([metricKey, metricValue]) => {
            if (metricValue.children) {
              return (
                <>
                  { Object.entries(metricValue.children)
                    .map(([subMetricKey, subMetricValue], subMetricIndex) => {
                      return (
                        <tr key={subMetricKey} className={styles.configuratorRow}>
                          <CategoryConfiguratorRow index={subMetricIndex} categoryKey={title} metricKey={metricKey} metricValue={metricValue} subMetricKey={subMetricKey} subMetricValue={subMetricValue} />
                        </tr>
                      )
                  })}
                  <tr className={styles.configuratorRowGap}></tr>
                </>
              );
            } else {
              return (
                <>
                  <tr key={metricKey} className={styles.configuratorRow}>
                    <CategoryConfiguratorRow index={0} categoryKey={title} metricKey={metricKey} metricValue={metricValue} subMetricKey={null} subMetricValue={null} />
                  </tr>
                  <tr className={styles.configuratorRowGap}>
                  </tr>
                </>
              )
            }
          }
        )}
      </>
    )
  };

  function CasinoSelector() {
    return (
      <div className={styles.casinoSelector}>
        <CustomSelect
          MenuProps={StyledMenuProps} 
          value={selectedCasino}
          onChange={(e: any) => {
            setSelectedCasino(e.target.value);
            setCasinoData(cloneDeep(data.casinos[e.target.value]));
          }}
        >
          {Object.keys(data.casinos).map((key) => (
            <MenuItem key={key} value={key}>
              <div className={styles.casinoIcon}>
                <img width="18px" height="18px" src={(data.casinos[key]["Info"] as CasinoCategory).children["Icon URL"].text}/>
                <span>{key}</span>
              </div>
            </MenuItem>
          ))}
        </CustomSelect>
      </div>
    )
  }

  function CloseButton() {
    return (
      <NavLink to={"/audits"}>
        <div className={styles.closeButton}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M2 2L12 12M2 12L12 2" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </NavLink>
    )
  }

  return (
    <div className={styles.container}>

      <div className={styles.left}>
        <div className={styles.header}>
          <h1>Score Calculator</h1>
          <CasinoSelector />
        </div>
        <div className={styles.content}>
          {/* <div className={styles.configuratorTitle}>
            Configurator
          </div> */}
          <table className={styles.configuratorTable}>
            <CategoryConfigurator title={"Compliance"} object={casinoData["Compliance"] as CasinoCategory} />
            <CategoryConfigurator title={"Responsible Gambling"} object={casinoData["Responsible Gambling"] as CasinoCategory} />
            <CategoryConfigurator title={"Security Measures"} object={casinoData["Security Measures"] as CasinoCategory} />
            <CategoryConfigurator title={"Support"} object={casinoData["Support"] as CasinoCategory} />
          </table>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.rightHeader}>
          <h1>Calculation</h1>
          <CloseButton />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.calculationMetrics}>
            <div className={styles.calculationMetric}>
              <span>Total Score</span>
              <ScoreBox object={casinoData} useColor={true} large={true} />
            </div>
            <div className={styles.calculationMetric}>
              <span>Potential Ranking</span>
              <div className={styles.rankingBox}>
                {(() => {
                  let ranking = getRelativeRanking(casinoData.score, data.casinos);
              
                  if (ranking > casinoData.rank) ranking -= 1;
              
                  return <>{ranking}</>;
                })()}
              </div>
            </div>
            <div className={styles.calculationMetric}>
              <span>Original Rank</span>
              <div className={styles.rankingBox}>{casinoData.rank}</div>
            </div>
          </div>
          <CategorySectionDivider symbol={"="} />
          <CategorySection title={"Compliance"} object={casinoData["Compliance"] as CasinoCategory} />
          <CategorySectionDivider symbol={"+"} />
          <CategorySection title={"Responsible Gambling"} object={casinoData["Responsible Gambling"] as CasinoCategory} />
          <CategorySectionDivider symbol={"+"} />
          <CategorySection title={"Security Measures"} object={casinoData["Security Measures"] as CasinoCategory} />
          <CategorySectionDivider symbol={"+"} />
          <CategorySection title={"Support"} object={casinoData["Support"] as CasinoCategory} />
        </div>
      </div>
      <div className={styles.mobileHeader}>
        <h1>Calculator</h1>
        <CasinoSelector />
        <CloseButton />
      </div>
    </div>
  );
}

const ScoreCalculatorPage = () => {
  
  const { data } = useGetReviewQuery();

  return (
    <div className="content-container content-main-container">
      { data && <ScoreCalculator data={data} /> }
    </div>
  );
}

export default ScoreCalculatorPage;
import { useParams } from 'react-router-dom';
import { useGetCreatorQuery, useGetCreatorsQuery } from '../../state/api';
import styles from './CreatorPage.module.scss';
import { CommunityCTA } from '../../components/CommunityCTA';
import { CreatorFAQ } from '../../components/CreatorFAQ';
import { Badge } from '../../components/Badge';
import { Creator, DealInfo, Icons } from '../../models/CreatorsData';
import { useState } from 'react';
import { KickStreamData } from '../../models/StreamData';
import StreamChart from './StreamChart';
import { format } from 'date-fns';

const dateOptions: Intl.DateTimeFormatOptions = { 
  day: 'numeric', 
  month: 'long', // Full month name (e.g., June)
  year: 'numeric' 
};

function formatDate(_date: Date) {
  const date = new Date(_date);

  console.log(date);

  const formattedDate: string = date.toLocaleDateString('en-GB', dateOptions);
  const formattedWithComma: string = formattedDate.replace(/ (\d{4})$/, ', $1');

  return formattedWithComma;
}

function formatNumber(num: number) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B'; // Billion
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'; // Million
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + 'k'; // Thousand
  } else {
    return num.toString(); // Less than 1000
  }
}

interface DealGroup {
  dealId: string;
  deals: DealInfo[];
}

function DealSection({deal, icons, selected}: {deal: DealGroup, icons: Icons, selected: boolean}) {
  // State to track whether the dropdown is open or closed
  const [isDropdownOpen, setIsDropdownOpen] = useState(selected);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  if (deal.deals.length == 0) return <></>;

  return (
    <>
      <div className={styles.deal}>
        <div className={styles.dealTop} onClick={toggleDropdown}>
          <div className={styles.dealTitle}>
            <div className={styles.dealCasino}>
              {deal.deals[0].casino}
            </div>
            <div className={styles.dealBadge}>
              <Badge level={deal.deals[0].verificationLevel} fontSize={19} />
            </div>
            <div className={styles.dealDates}>
              <div className={styles.largeDates}>
                {deal.deals[0].type === "Previous" ? <>From {formatDate(deal.deals[0].startDate)} to {formatDate(deal.deals[0].endDate as Date)}</> : <>Date started: {formatDate(deal.deals[0].startDate)}</>}
              </div>
              <div className={styles.smallDates}>
                {deal.deals[0].type === "Previous" ? <>{new Date(deal.deals[0].startDate).toLocaleDateString()} to {new Date(deal.deals[0].endDate as Date).toLocaleDateString()}</> : <>Started: {new Date(deal.deals[0].startDate).toLocaleDateString()}</>}
              </div>
            </div>
          </div>
          <div className={styles.dealDropdown}>
            <svg className={isDropdownOpen ? styles.rotate : ''} xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
              <path d="M7.43301 11.25C7.24056 11.5833 6.75944 11.5833 6.56699 11.25L0.504809 0.749998C0.31236 0.416665 0.552922 -1.263e-06 0.937822 -1.22935e-06L13.0622 -1.6941e-07C13.4471 -1.35761e-07 13.6876 0.416667 13.4952 0.75L7.43301 11.25Z" fill="#B8B8B8"/>
            </svg>
          </div>
        </div>
        {/* Conditionally render the dropdown content based on isDropdownOpen */}
        {isDropdownOpen && deal.deals.map(x => {
          return (
            <div className={styles.dealContent}>
              <div className={styles.dealPlatform}>
                <img height="24px" src={icons[x.platform] ?? ""}/>
                {x.platform}
              </div>
              <div>
                <div style={{marginBottom: "25px"}} className={styles.dealRequirements}>
                  <div className={styles.dealSubTitle}>
                    Requirements
                  </div>
                  <div className={styles.dealInfoPieces}>
                    {x.requirements.map((req, index) => {
                        return (
                          <div key={index} className={styles.dealInfoPiece}>
                            <div className={styles.dealInfoIcon}>
                              <img height="24px" src={icons[req.icon] ?? ""}/>
                            </div>
                            <div className={styles.dealInfoText}>
                              {req.description}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className={styles.dealPayments}>
                  <div className={styles.dealSubTitle}>
                    Payment
                  </div>
                  <div className={styles.dealInfoPieces}>
                    {x.payments.map((payment, index) => {
                        return (
                          <div key={index} className={styles.dealInfoPiece}>
                            <div className={styles.dealInfoIcon}>
                              <img height="24px" src={icons[payment.icon] ?? ""}/>
                            </div>
                            <div className={styles.dealInfoText}>
                              {payment.description}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        )}
      </div>
    </>
  )
}

function StreamSection({kickStream, creator, selected}: {kickStream: KickStreamData, creator: Creator, selected: boolean}) {
  // State to track whether the dropdown is open or closed
  const [isDropdownOpen, setIsDropdownOpen] = useState(selected);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className={styles.stream}>
        <div className={styles.streamTop} onClick={toggleDropdown}>
          <div className={styles.streamTitle}>
            <div className={`${styles.dealCasino}`}>
              {kickStream.endTime === null ? <><div className={styles.liveIcon}></div>Live - "</> : ""}{kickStream.title}...
            </div>
            <div className={styles.dealDates}>
              {/* {deal.type === "Previous" ? <>From {formatDate(deal.startDate)} to {formatDate(deal.endDate as Date)}</> : <>Date started: {formatDate(deal.startDate)}</>} */}
              {formatDate(new Date(kickStream.startTime))}
            </div>
          </div>
          <div className={styles.dealDropdown}>
            <svg className={isDropdownOpen ? styles.rotate : ''} xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
              <path d="M7.43301 11.25C7.24056 11.5833 6.75944 11.5833 6.56699 11.25L0.504809 0.749998C0.31236 0.416665 0.552922 -1.263e-06 0.937822 -1.22935e-06L13.0622 -1.6941e-07C13.4471 -1.35761e-07 13.6876 0.416667 13.4952 0.75L7.43301 11.25Z" fill="#B8B8B8"/>
            </svg>
          </div>
        </div>
        {/* Conditionally render the dropdown content based on isDropdownOpen */}
        {isDropdownOpen && (
          <div className={styles.streamContent}>
            {/* {JSON.stringify(kickStream)} */}
            <div className={styles.streamGraph}>
              <StreamChart data={kickStream.snapshots}/>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

function CreatorPage() {
  const { data } = useGetCreatorsQuery();
  const { creatorId } = useParams();

  const creator = data?.creators.find(x => x.name.toLowerCase() === creatorId?.toLowerCase())

  const kickUrl = creator?.socials.find(x => x.platform === "Kick")?.link;

  const kickUsername = kickUrl === undefined ? "" : kickUrl.split("/")[kickUrl.split("/").length - 1];

  const { data: streamData, isLoading: streamDataLoading } = useGetCreatorQuery(kickUsername);


  if (!data || !creator) {
    return (
      <div className="content-container content-main-container ">
        <div className={styles.content}>
          <div className={styles.noCreatorElement}>
            <div>
              Error 404: Unknown Creator
            </div>
          </div>
        </div>
      </div>
    );
  }

  const dealList = creator.deals;

  const groupedDeals = dealList.reduce((acc, deal) => {
    if (deal.verificationLevel > 0) {
        if (!acc[deal.dealId]) {
            acc[deal.dealId] = [];
        }
        acc[deal.dealId].push(deal);
    }
    return acc;
  }, {} as Record<string, DealInfo[]>);

  // Step 2: Sort dealIds in ascending order
  const sortedDealIds = Object.keys(groupedDeals).sort((a, b) => a.localeCompare(b));

  // Step 3: Create a sorted grouped array
  const sortedGroupedDeals = sortedDealIds.map(dealId => ({
      dealId,
      deals: groupedDeals[dealId],
  }));

  const { currentDealGroups, previousDealGroups } = sortedGroupedDeals.reduce(
    (result, group) => {
        // Check the type of the first deal in each group
        if (group.deals[0]?.type === "Current") {
            result.currentDealGroups.push(group);
        } else {
            result.previousDealGroups.push(group);
        }
        return result;
    },
    { currentDealGroups: [], previousDealGroups: [] } as { currentDealGroups: typeof sortedGroupedDeals; previousDealGroups: typeof sortedGroupedDeals }
);

  console.log(currentDealGroups);
  console.log(previousDealGroups);

  const bestDeal = dealList.filter(x => x.type == "Current").reduce((highest, deal) => {
    return deal.verificationLevel > highest.verificationLevel ? deal : highest;
  }, dealList.filter(x => x.type == "Current")[0]);

  // group by deal id

  // use first deal for base data

  return (
    <div className="content-container content-main-container ">
      <div className={styles.content}>
        <div className={styles.layout}>
          <div className={styles.top}>
            <div className={styles.topLeft}>
              <div className={styles.profileCard}>
                <div className={styles.pfpImg}>
                  <img width="133px" height="133px" src={creator.pfp ?? ""}/>
                  <div className={styles.pfpLevel}>
                    <Badge level={bestDeal.verificationLevel} fontSize={30} />
                  </div>
                </div>
                <div className={styles.pfpName}>
                  {creator.name}
                </div>
                <div className={styles.pfpSocials}>
                  {creator.socials.map((social, index)  => {
                    return (
                      <a key={index} href={social.link} target="_blank" rel="noopener noreferrer">
                        <div className={styles.socialIcon}>
                          <img width="20px" height="20px" src={data?.icons[social.platform] ?? ""}/>
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
              <div className={styles.currentVerificationSection}>
                <div className={styles.currentVerificationTitle}>
                  Current Verification Level
                </div>
                <div className={styles.verificationLevels}>
                  <div className={`${styles.levelSection} ${bestDeal.verificationLevel === 0 ? styles.levelSectionHighlight : {}}`}>
                    <div className={styles.levelLeft}>
                      Level
                      <div className={styles.levelLeftBadge}>
                        <Badge level={0} fontSize={15} />
                      </div>
                    </div>
                    <div className={styles.levelRight}>
                      The deal has not been verified by Tanzanite.
                    </div>
                  </div>
                  <div className={`${styles.levelSection} ${bestDeal.verificationLevel === 1 ? styles.levelSectionHighlight : {}}`}>
                    <div className={styles.levelLeft}>
                      Level
                      <div className={styles.levelLeftBadge}>
                        <Badge level={1} fontSize={15} />
                      </div>
                    </div>
                    <div className={styles.levelRight}>
                      Deal details have been confirmed by the creator and the casino.
                    </div>
                  </div>
                  <div className={`${styles.levelSection} ${bestDeal.verificationLevel === 2 ? styles.levelSectionHighlight : {}}`}>
                    <div className={styles.levelLeft}>
                      Level
                      <div className={styles.levelLeftBadge}>
                        <Badge level={2} fontSize={15} />
                      </div>
                    </div>
                    <div className={styles.levelRight}>
                      Tanzanite has verified the terms of the deal.
                    </div>
                  </div>
                  <div className={`${styles.levelSection}`}>
                    <div className={styles.levelLeft}>
                      Level
                      <div className={styles.levelLeftBadge}>
                        <Badge level={3} fontSize={15} />
                      </div>
                    </div>
                    <div className={styles.levelRight}>
                      Advanced verification is coming soon...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.topRight}>
              <div className={styles.about}>
                <div className={styles.sectionHeader}>
                  About {creator.name}
                </div>
                <div className={styles.description}>
                  <p style={{whiteSpace: "pre-line"}}>{creator.about.replaceAll(`"`, ``)}</p>
                </div>
                <div className={styles.sectionSubHeader}>
                  Socials
                </div>
                <div className={styles.socials}>
                  {creator.socials.map((social, index)  => {
                    return (
                      <a key={index} href={social.link} target="_blank" rel="noopener noreferrer">
                        <div className={styles.social}>
                          <div className={styles.socialIcon}>
                            <img width="30px" height="30px" src={data?.icons[social.platform] ?? ""}/>
                          </div>
                          <div className={styles.socialFollowers}>
                            {/* {formatNumber(social.followers)} */}
                            {social.platform}
                          </div>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className={styles.currentDeals}>
                <div className={styles.sectionHeader}>
                  Current Deals
                </div>
                <div className={styles.description}>
                  Overview of all the current deals that this streamer has
                </div>
                <div className={styles.dealsContainer}>
                  {currentDealGroups.map((x, index)  => {
                    return <DealSection key={index} icons={data.icons} deal={x} selected={index === 0} />
                  })}
                  {currentDealGroups.length == 0 ? (
                    <div className={styles.deal}>
                      <div className={`${styles.dealTop} ${styles.noData}`}>
                        No deals verified by Tanzanite
                      </div>
                    </div>
                  ) : <></>}
                </div>
              </div>
              <div className={styles.dealHistory}>
                <div className={styles.sectionHeader}>
                  Deal History
                </div>
                <div className={styles.description}>
                  Overview of all the deals that this streamer has done since Tanzanite launch
                </div>
                <div className={styles.dealsContainer}>
                  {previousDealGroups.map((x, index)  => {
                    return <DealSection key={index} icons={data.icons} deal={x} selected={index === 0}/>
                  })}
                  {previousDealGroups.length == 0 ? (
                    <div className={styles.deal}>
                      <div className={`${styles.dealTop} ${styles.noData}`}>
                        No deal history recorded
                      </div>
                    </div>
                  ) : <></>}
                </div>
              </div>
              <div className={styles.streams}>

                <div className={styles.sectionHeader}>
                  Streams
                </div>
                <div className={styles.description}>
                  <p>Analytics of the recent streams by this creator in the "Slots & Casino" category</p>
                </div>
                <div className={styles.dealsContainer}>
                  {!streamData || streamData?.kickStreams.length === 0 ?  <div className={styles.deal}><div className={`${styles.dealTop} ${styles.noData}`}>No Streams Found</div></div>  : <></>}
                  {streamData?.kickStreams.map((kickStream, index) => (
                    <StreamSection kickStream={kickStream} creator={creator} key={index} selected={index === 0}/>
                  ))}
                </div>
              </div>
              <div className={styles.faq}>
                <CreatorFAQ />
              </div>
              <CommunityCTA />
            </div>
          </div>
          <div className={styles.bottom}>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorPage;
import { CasinoDropdown, ChainDropdown } from '../../../components/Selectors';
import { DashboardData } from '../../../models/DashboardData';
import styles from './Controls.module.scss';

interface ControlsProps {
  dashboardData: DashboardData | undefined;
  timeframe: string;
  setTimeframe: React.Dispatch<React.SetStateAction<string>>;
  selectedCasinos: string[];
  setSelectedCasinos: React.Dispatch<React.SetStateAction<string[]>>;
  selectedChains: string[];
  setSelectedChains: React.Dispatch<React.SetStateAction<string[]>>;
}

export function Controls({dashboardData, timeframe, setTimeframe, selectedCasinos, setSelectedCasinos, selectedChains, setSelectedChains} : ControlsProps) {
  return (
    <div className={styles.controls}>
      <div className={`${styles.control} ${styles.control1}`}>
        <span>Casino</span>
        <CasinoDropdown data={dashboardData} selectedItems={selectedCasinos} setSelectedItems={setSelectedCasinos}/>
      </div>
      <div className={`${styles.control}  ${styles.control2}`}>
        <span>Chain</span>
        <ChainDropdown data={dashboardData} selectedItems={selectedChains} setSelectedItems={setSelectedChains}/>
      </div>
      <div className={`${styles.control}  ${styles.control3}`}>
        <div className={`${styles.timeframeButton} ${timeframe === "week" ? styles.active : ''}`} onClick={() => setTimeframe("week")}>Week</div>
        <div className={`${styles.timeframeButton} ${timeframe === "month" ? styles.active : ''}`} onClick={() => setTimeframe("month")}>Month</div>
        <div className={`${styles.timeframeButton} ${timeframe === "year" ? styles.active : ''}`} onClick={() => setTimeframe("year")}>Year</div>
      </div>
    </div>
  )
}
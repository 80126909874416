// AboutPage.tsx
import React from 'react';
import styles from './AboutPage.module.scss';

function AboutPageHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <span>About Tanzanite</span>
      </div>
    </div>
  )
}

function AboutPage() {
  return (
    <div className="content-container content-main-container">
      <div className={styles.content}>
        <AboutPageHeader />
        <div className={styles.body}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
               Overview 
            </div>
            <div className={styles.sectionContent}>
              <p>
                With over 10 years of experience in the gambling industry, Tanzanite specialises in cryptocurrency gambling. Our expertise extends to growing businesses and creating value through custom analytics, industry experience and innovative marketing strategies.
                <br /><br />Our longstanding involvement in the industry has provided us with a nuanced understanding of it’s unique challenges and opportunities. This deep insight equips us to address the complexities of the cryptocurrency gambling world, ensuring that we deliver value to our clients whilst adhering to best practices for safe gambling.
              </p>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
               Our Mission 
            </div>
            <div className={styles.sectionContent}>
              <p>
                Our mission is to establish a new standard for the casino industry by conducting thorough audits and ensuring compliance, security, support, and safe gambling practices. We aim to enhance the well-being and safety of players while holding the industry to the highest standards.
                <br /><br />Our key objectives are:
                <ul>
                  <li>Create an industry standard for the casino industry.</li>
                  <li>Improve the well-being and safety of players.</li>
                  <li>Assist operators in their journey to improve.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
               Supporters
            </div>
            <div className={styles.sectionContent}>
              <p>
                We remain an independent authority, and our supporters do not influence our scoring or weightings. If you'd like to support our mission of improving the gambling industry, reach out via our contact page or on X.
              </p>
              <div className={styles.supporters}>
                <img className={styles.supporterLogo} src="https://i.imgur.com/gGBxI6V.png"></img>
                <img className={styles.supporterLogo} src="https://i.imgur.com/n736RPZ.png"></img>
                <img className={styles.supporterLogo} src="https://i.imgur.com/gwlAcqq.png"></img>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
               Disclaimer
            </div>
            <div className={styles.sectionContent}>
              <p>
                <ul>
                  <li>As part of our ongoing consultancy, we may engage with other casinos under confidentiality agreements.</li>
                  <li>Some of our staff have past or present associations with Rollbit.com.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
}

export default AboutPage;

import { Logo } from "./Logo";
import styles from "./CommunityCTA.module.scss";

export function CommunityCTA() {
  return (  
    <div className={styles.communityContainer}>
      <div className={styles.communityContent}>
        <div className={styles.communityTitle}>
          Join the Tanzanite Community
        </div>
        <div className={styles.communityDescription}>
          <p>Keep up to date on everything related to the crypto casino industry</p> 
        </div>
        <div className={styles.communityButtons}>
        <a href={"https://discord.gg/tanzanite"} target="_blank" rel="noopener noreferrer">
          <div className={styles.discordButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path d="M20.8579 5.76967C19.4183 5.09858 17.8597 4.6115 16.2361 4.33008C16.2075 4.33048 16.1803 4.34216 16.1603 4.36255C15.9655 4.71974 15.7381 5.18518 15.5866 5.54237C13.8645 5.28276 12.1132 5.28276 10.3911 5.54237C10.2395 5.17435 10.0122 4.71974 9.80658 4.36255C9.79576 4.3409 9.76329 4.33008 9.73082 4.33008C8.10721 4.6115 6.55938 5.09858 5.10896 5.76967C5.09813 5.76967 5.08731 5.7805 5.07649 5.79132C2.13235 10.1967 1.32055 14.483 1.72104 18.726C1.72104 18.7477 1.73186 18.7693 1.75351 18.7801C3.70184 20.2089 5.57439 21.0748 7.4253 21.6485C7.45777 21.6593 7.49024 21.6485 7.50107 21.6269C7.93403 21.0315 8.32369 20.4038 8.65924 19.7435C8.68089 19.7002 8.65924 19.6569 8.61594 19.6461C7.99897 19.4079 7.41448 19.1265 6.8408 18.8018C6.79751 18.7801 6.79751 18.7152 6.82998 18.6827C6.94904 18.5961 7.06811 18.4987 7.18717 18.4121C7.20882 18.3905 7.24129 18.3905 7.26294 18.4013C10.9864 20.1007 15.0021 20.1007 18.6823 18.4013C18.7039 18.3905 18.7364 18.3905 18.7581 18.4121C18.8771 18.5095 18.9962 18.5961 19.1152 18.6936C19.1585 18.726 19.1585 18.791 19.1044 18.8126C18.5416 19.1482 17.9462 19.4188 17.3293 19.6569C17.286 19.6677 17.2752 19.7218 17.286 19.7543C17.6324 20.4146 18.022 21.0424 18.4442 21.6377C18.4766 21.6485 18.5091 21.6593 18.5416 21.6485C20.4033 21.0748 22.2759 20.2089 24.2242 18.7801C24.2458 18.7693 24.2567 18.7477 24.2567 18.726C24.7329 13.8227 23.4665 9.5689 20.9012 5.79132C20.8904 5.7805 20.8796 5.76967 20.8579 5.76967ZM9.22209 16.1391C8.10721 16.1391 7.17635 15.1108 7.17635 13.8444C7.17635 12.578 8.08557 11.5497 9.22209 11.5497C10.3694 11.5497 11.2787 12.5888 11.2678 13.8444C11.2678 15.1108 10.3586 16.1391 9.22209 16.1391ZM16.7664 16.1391C15.6516 16.1391 14.7207 15.1108 14.7207 13.8444C14.7207 12.578 15.6299 11.5497 16.7664 11.5497C17.9138 11.5497 18.823 12.5888 18.8122 13.8444C18.8122 15.1108 17.9138 16.1391 16.7664 16.1391Z" fill="white"/>
            </svg>
            Join our Discord
          </div>
          </a>
          <a href={"https://x.com/Tanzanite_xyz"} target="_blank" rel="noopener noreferrer">
          <div className={styles.xButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
              <g clip-path="url(#clip0_2972_1303)">
              <path d="M15.8387 1.91016H18.9225L12.1851 9.61127L20.1116 20.0886H13.9055L9.04513 13.7333L3.48256 20.0886H0.397085L7.60379 11.8512L-0.00012207 1.91099H6.36356L10.7571 7.71993L15.8387 1.91016ZM14.7569 18.2434H16.4655L5.43507 3.65904H3.60155L14.7569 18.2434Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_2972_1303">
              <rect width="20.1117" height="20.1117" fill="white" transform="translate(0 0.944336)"/>
              </clipPath>
              </defs>
            </svg>
            Follow us on X
          </div>
          </a>
        </div>
      </div>
      <div className={styles.communityIcon}>
        <Logo size={124}/>
      </div>
    </div>
  )
}
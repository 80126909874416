import React, { useEffect, useState } from 'react';
import styles from './Analytics.module.scss';
import { CasinoDropdown, ChainDropdown } from '../../components/Selectors';
import { useGetDashboardQuery } from '../../state/api';
import TotalDepositedSection from './sections/TotalDeposited';
import TokenBreakdownSection from './sections/TokenBreakdown';
import LineChart from './sections/LineChart';
import { FullLogo } from '../../components/Logo';
import { ChainData, ChainTimeframeData, DashboardData, DataBlock_Clean, DataBlockAnalytics, EntityData, GroupedTransfers_Clean } from '../../models/DashboardData';
import { Controls } from './sections/Controls';
import MarketShareSection from './sections/MarketShare';
import { AggregateDashboardData } from './Utility';


function AnalyticsPageHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        Analytics
      </div>
      <div className={styles.subTitle}>
        A public analytics dashboard for comparing casinos on the Ethereum network.
      </div>
    </div>
  )
}

function AnalyticsPage() {  
  const { data, error, isLoading } = useGetDashboardQuery();


  useEffect(() => {
    console.log("data has loaded")

    if (!data) return;

    setDashboardData(JSON.parse(JSON.stringify(data)));

    setSelectedCasinos(["Stake", "Rollbit", "Roobet", "Gamdom", "Shuffle"]);
  }, [data]);

  const [dashboardData, setDashboardData] = useState<DashboardData | undefined>(undefined);
  const [selectedCasinos, setSelectedCasinos] = useState<string[]>([]);
  const [selectedChains, setSelectedChains] = useState<string[]>(["ETH", "SOL", "BSC", "TRX"]);
  const [timeframe, setTimeframe] = useState('year');

  AggregateDashboardData(dashboardData, selectedChains);

  const lastUpdatedText = () => {
    if (!dashboardData) return "Loading...";

    const lastUpdate = new Date(dashboardData.lastUpdateEnd);
    const currentTime = new Date();

    const millisecondsDiff = currentTime.getTime() - lastUpdate.getTime();
    const minutes = Math.ceil(millisecondsDiff / 60000);
    const hours = Math.floor(minutes / 60);

    const diff = hours > 0 ? `${hours}h ${minutes % 60}m` : `${minutes}m`;

    return `Last updated: ${diff} ago`;
  }

  return (
    <div className="content-container content-main-container">
      <AnalyticsPageHeader />
      <div className={styles.lastUpdatedContainer}>
        {<div className={styles.lastUpdated}>{lastUpdatedText()}</div>}
      </div>
      <div className={styles.dashboard}>
        <div className={styles.top}>
          <Controls dashboardData={dashboardData} timeframe={timeframe} setTimeframe={setTimeframe} selectedCasinos={selectedCasinos} setSelectedCasinos={setSelectedCasinos} selectedChains={selectedChains} setSelectedChains={setSelectedChains} />
          <div className={styles.analyticsItem}>
            <LineChart dashboardData={dashboardData} selectedCasinos={selectedCasinos} timeframe={timeframe} selectedChains={selectedChains}/>
          </div>
        </div>
        <div className={styles.analyticsItem}>
          <MarketShareSection dashboardData={dashboardData} selectedCasinos={selectedCasinos} timeframe={timeframe} selectedChains={selectedChains}/>
        </div>
        <div className={styles.analyticsItem}>
          <TokenBreakdownSection dashboardData={dashboardData} selectedCasinos={selectedCasinos} timeframe={timeframe} selectedChains={selectedChains}/>
        </div>
        {/*<div className={styles.bottom}>
          <div className={styles.analyticsItem}>
            <div className={styles.sectionHeader}>
              Market Share
            </div>
            <MarketShareSection dashboardData={formattedData} timeframe={timeframe}/>
          </div>
          <div className={styles.analyticsItem}>
            <div className={styles.sectionHeader}>
              Token Breakdown
            </div>
            <TokenBreakdownSection dashboardData={formattedData} selectedCasinos={selectedCasinos} timeframe={timeframe}/>
          </div>
          <div className={styles.analyticsItem}>
            <div className={styles.sectionHeader}>
              Total Deposited
            </div>
            <TotalDepositedSection dashboardData={formattedData} selectedCasinos={selectedCasinos} timeframe={timeframe}/>
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default AnalyticsPage;
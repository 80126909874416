import React from 'react';
import { Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Typography, SelectChangeEvent, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import { DashboardData } from '../models/DashboardData';
import { Box } from 'victory';
import styles from './Selectors.module.scss';

const StyledOutlinedInput = styled(OutlinedInput)({
  '& .MuiOutlinedInput-notchedOutline': {

    '& legend': {
      display: "none"
    }
  },
});

const StyledSelect = styled(Select)<{ width?: string }>(({ width }) => ({
  minWidth: width || "150px",
  height: "40px",
  backgroundColor: 'transparent', // Example background color
  color: '#fff', // Text color,
  fontFamily: "Inter",
  fontSize: "14px",
  borderRadius: "5px",
  border: '2px solid #2D395C', // Border color
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Border color
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Hover border color
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',  // Border color when focused
  },
  '& .MuiSvgIcon-root': {
    color: '#BEC6DD', // Sets the color of the dropdown arrow
  },
  '& .MuiTypography-root': {
    fontFamily: 'Inter, sans-serif !important', // Ensuring the font is applied
    fontSize: '14px !important',
    color: '#fff !important'
  }
}));

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: '#2D395C', // Example background colorm
  fontFamily: "Inter",
  color: "#fff",
  '&.Mui-selected': {
    backgroundColor: '#2D395C',
    color: '#F658DD', // Selected item text color
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#26304D', // Hover on selected item
  }
});

const MenuProps = {
    PaperProps: {
      sx: {
        width: 250,
        backgroundColor: '#2D395C', // Example background color
        fontFamily: "Inter",
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
        }
      }
    },
}

const ChainMenuProps = {
  ...JSON.parse(JSON.stringify(MenuProps)),
}
ChainMenuProps.PaperProps.sx.width = 200;

interface CasinoDropdownProps {
  data: DashboardData | undefined;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CasinoDropdown = ({ data, selectedItems, setSelectedItems }: CasinoDropdownProps ) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value; // `value` is `unknown` here
    setSelectedItems(typeof value === 'string' ? [value] : value as string[]);
  };

  const chainOrder = ["ETH", "SOL", "BSC", "TRX"];

  const options = !data ? [] : data.entities.map((entity) => ({
    id: entity.id,
    name: entity.name,
    iconUrl: entity.iconUrl,
    chains: entity.chains.filter(x => x.addresses.length > 0 && x.chain !== "AGG").map((chain) => chain.chain).sort((a, b) => chainOrder.indexOf(a) - chainOrder.indexOf(b))
  }));

  return (
    <StyledSelect
      width={"200px"}
      multiple
      value={selectedItems}
      onChange={handleChange}
      input={<StyledOutlinedInput notched={false} />}
      renderValue={(selected: any) => selected.length > 1 ? `${selected.length} selected` : selected.map((x: string) => options.find(op => op.id == x)?.name).join(', ')}
      MenuProps={MenuProps}
    >
      {options.map((option) => (
        <StyledMenuItem key={option.id} value={option.id}>
          <div className={styles.menuItemContainer}>
            <div className={styles.menuItemLeft}>
                <img src={option.iconUrl} alt={`Icon ${option.name}`} className={styles.menuItemIcon} /> 
                <span>{option.name}</span>
            </div>
            <div className={styles.menuItemRight}>
               {
                  option.chains.map((chain) => (
                    <img src={data?.chainInfo.find(x => x.chain == chain)?.iconUrl} alt={`Icon ${chain}`} className={styles.menuItemIcon2} />
                  ))
               }
            </div>
          </div>
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
}

export const ChainDropdown = ({ data, selectedItems, setSelectedItems }: CasinoDropdownProps ) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value; // `value` is `unknown` here
    setSelectedItems(typeof value === 'string' ? [value] : value as string[]);
  };

  const chainOrder = ["ETH", "SOL", "BSC", "TRX"];

  const options = !data ? [] : data.chainInfo.map((chain) => ({
    id: chain.chain,
    name: chain.chain,
    iconUrl: chain.iconUrl,
  })).sort((a, b) => chainOrder.indexOf(a.id) - chainOrder.indexOf(b.id));

  return (
    <StyledSelect
      width={"200px"}
      multiple
      value={selectedItems}
      onChange={handleChange}
      input={<StyledOutlinedInput notched={false} />}
      renderValue={(selected: any) => selected.length > 4 ? `${selected.length} selected` : selected.map((x: string) => options.find(op => op.id == x)?.name).join(', ')}
      MenuProps={ChainMenuProps}
    >
      {options.map((option) => (
        <StyledMenuItem key={option.id} value={option.id}>
          <div className={styles.menuItemContainer}>
            <div className={styles.menuItemLeft}>
                <img src={option.iconUrl} alt={`Icon ${option.name}`} className={styles.menuItemIcon} /> 
                <span>{option.name}</span>
            </div>
          </div>
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
}
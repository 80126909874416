import { useState } from "react";
import { Logo } from "./Logo";
import styles from "./CreatorFAQ.module.scss";
import { Badge } from "./Badge";

function ToggleSVG({isOpen}: {isOpen: boolean}) {

  if (isOpen) {
    return (
      <div className={styles.plusContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="4" viewBox="0 0 20 4" fill="none">
          <path d="M0.666504 3.33366V0.666992H19.3332V3.33366H0.666504Z" fill="#F434D5"/>
        </svg>
      </div>
    )
  } else {
    return (
      <div className={styles.plusContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <mask id="mask0_2972_3925" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_2972_3925)">
          <path d="M14.6667 28V17.3333H4V14.6667H14.6667V4H17.3333V14.6667H28V17.3333H17.3333V28H14.6667Z" fill="white"/>
          </g>
        </svg>
      </div>
    )
  }
}

// Single FAQ Question Component
function FAQ_Question({ title, content }: { title: string, content: JSX.Element }) {
  // State to toggle FAQ visibility
  const [isOpen, setIsOpen] = useState(title === "How are deals verified?" ? true : false);

  // Function to toggle the visibility
  const toggleFAQ = () => setIsOpen(!isOpen);

  return (
    <div className={styles.faqQuestion}>
      <div className={styles.faqTop} onClick={toggleFAQ}>
        <div className={styles.faqTopTitle}>{title}</div>
        <ToggleSVG isOpen={isOpen} />
      </div>
      {/* Toggle faqBottom visibility based on isOpen state */}
      {isOpen && (
        <div className={styles.faqBottom}>
          {content}
        </div>
      )}
    </div>
  );
}

export function CreatorFAQ() {
  // FAQ data array with title and content
  const faqQuestions = [
    {
      title: 'How are deals verified?',
      content: (
        <>
          Tanzanite verifies deals to ensure that the system is accurate and transparent without exposing confidential details. Each of the verification levels includes the criteria from the level below it.
        
          <div className={styles.verificationLevels}>
            <div className={`${styles.levelSection}`}>
              <div className={styles.levelLeft}>
                Level
                <div className={styles.levelLeftBadge}>
                  <Badge level={0} fontSize={19} />
                </div>
              </div>
              <div className={styles.levelRight}>
                The deal has not been verified by Tanzanite.
              </div>
            </div>
            <div className={`${styles.levelSection}`}>
              <div className={styles.levelLeft}>
                Level
                <div className={styles.levelLeftBadge}>
                  <Badge level={1} fontSize={19} />
                </div>
              </div>
              <div className={styles.levelRight}>
                Deal details have been confirmed by the creator and the casino.
              </div>
            </div>
            <div className={`${styles.levelSection}`}>
              <div className={styles.levelLeft}>
                Level
                <div className={styles.levelLeftBadge}>
                  <Badge level={2} fontSize={19} />
                </div>
              </div>
              <div className={styles.levelRight}>
                Tanzanite has verified the terms of the deal.
              </div>
            </div>
            <div className={`${styles.levelSection}`}>
              <div className={styles.levelLeft}>
                Level
                <div className={styles.levelLeftBadge}>
                  <Badge level={3} fontSize={19} />
                </div>
              </div>
              <div className={styles.levelRight}>
                Advanced verification is coming soon...
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'What type of insight is given within a deal?',
      content: (
        <>
          In order to ensure creators and operators and not put at a competitive disadvantage for opting in for transparency, deals overviews are kept high level. This ensures the viewer knows how the streamers deal works, but without the exact numbers.
        </>
      )
    },
    {
      title: 'How can I trust the information is legit?',
      content: (
        <>
          Tanzanite verify all information to ensure no operator or streamer is trying to mislead viewers. On top of this, we monitor and analyse streams to determine if the deal is feasible and makes sense.
        </>
      )
    },
    {
      title: 'How can I get verified?',
      content: (
        <>
          If you'd like to get verified please reach out to us on X or complete the form on the main creators page.
        </>
      )
    },
    {
      title: 'How can I dispute a creators deal terms?',
      content: (
        <>
          Our number one priority is to ensure Tanzanite is a trusted 3rd party, allowing us to have a voice in shaping the industry for the better. If you have any concerns or evidence to support information on the platform is wrong, please contact us on X or via the contact form.
        </>
      )
    }
  ];

  return (
    <div className={styles.faqContainer}>
      <div className={styles.faq}>
        <div className={styles.faqTitle}>
          Frequently Asked Questions
        </div>
        <div className={styles.faqList}>
          {faqQuestions.map((faq, index) => (
            <div key={index}>
              <FAQ_Question title={faq.title} content={faq.content} />
              {/* Add separator between questions */}
              {index < faqQuestions.length - 1 && (
                <div className={styles.faqSeparator}></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
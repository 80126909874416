import React from 'react';
import styles from './Footer.module.scss';
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";



const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="content-container">
                <div className={styles.footerContent}>
                    <div className={styles.footerContent__left}>
                        <p>© 2024 Tanzanite</p>
                    </div>
                    <div className={styles.footerContent__right}>
                        <a href="https://www.begambleaware.org/" target="_blank" rel="noopener noreferrer">
                            <p>Gamble Aware</p>
                        </a>
                        <a href="https://discord.gg/tanzanite" target="_blank" rel="noopener noreferrer">
                            <FaDiscord size={24} />
                        </a>    
                        <a href="https://x.com/Tanzanite_xyz" target="_blank" rel="noopener noreferrer">
                            <FaXTwitter size={22} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import { VictoryLegend, VictoryPie } from 'victory';
import styles from './TokenBreakdown.module.scss';
import tableStyles from "./Tables.module.scss";
import analyticsStyles from '../Analytics.module.scss';
import { DashboardData, GroupedTransfers_Clean } from '../../../models/DashboardData';
import { ToSmallNumber } from '../Utility';
import SectionHeader from './SectionHeader';

// function aggregateDistributions(distributions: { [key: string]: number }) {
//   // Convert the distributions object into an array of entries
//   const entries = Object.entries(distributions);

//   // Sort the entries by value in descending order
//   entries.sort((a, b) => b[1] - a[1]);

//   // Create a new object for the top 4 entries and the "other" category
//   const newDistributions: { [key: string]: number } = {};
//   let other = 0;

//   for (let i = 0; i < entries.length; i++) {
//     if (i < 5) {
//       // This is one of the top 4 entries, so add it to the new object
//       newDistributions[entries[i][0]] = entries[i][1];
//     } else {
//       // This is not one of the top 4 entries, so add its value to the "other" category
//       other += entries[i][1];
//     }
//   }

//   if (other !== 0) {  
//     newDistributions.OTHER = other;
//   }

//   return newDistributions;
// }

// function TokenBreakdownSection({dashboardData, selectedCasinos, timeframe}: {dashboardData: any, selectedCasinos: string[], timeframe: string}) {
//   const chartData = [];
//   const legendData = [];
//   const distributions: { [key: string]: number } = {};

//   if (!(dashboardData === undefined || JSON.stringify(dashboardData) === "{}")) {
//     for (const casino of selectedCasinos) {
//       for (const token of Object.keys(dashboardData[casino][timeframe].tokenDistribution)) {
//         distributions[token] = (distributions[token] || 0) + dashboardData[casino][timeframe].tokenDistribution[token];
//       }
//     }
  
//     const distributionsAggregated = aggregateDistributions(distributions);
  
//     for (const token of Object.keys(distributionsAggregated)) {
//       chartData.push({x: token, y: distributionsAggregated[token]});
//     }
  
  
//     for (const token of Object.keys(distributionsAggregated)) {
//       legendData.push({name: token, symbol: { fill: colorMapping[token] }})
//     }
//   }

//   const total = chartData.reduce((acc, data) => acc + data.y, 0);
  
//   return (
//     <div className={styles.tokenBreakdownSection}>
//       <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
//         <VictoryLegend y={-5} x={5} height={30} width={500}
//             orientation="horizontal"
//             data={legendData}
//             gutter={20}
//             style={{
//               labels: { fill: "#aaa", fontFamily: "Roboto Mono, monospace" },
//             }}
//         />
//         <VictoryPie
//           width={295}
//           height={252}
//           data={chartData}
//           padding={{ top: 5, bottom: 0, left: 50, right: 50 }}
//           labels={({ datum }) => `${datum.y / total * 100 > 2 ? `${(datum.y / total * 100).toFixed(1)}%` : ""}`}
//           labelRadius={({ innerRadius }) => {
//             const radius = typeof innerRadius === 'function' ? innerRadius({}) : innerRadius || 0;
//             return radius + 107;
//           }}
//           colorScale={chartData.map(datum => colorMapping[datum.x])}
//           style={{
//             data: {
//               fillOpacity: 0.85, stroke: "#fff", strokeWidth: 0
//             },
//             labels: {
//               fontSize: 10, fill: "#aaa", fontFamily: "Roboto Mono, monospace"
//             }
//           }}
//         />
//       </div>
//     </div>
//   );
// }

function MarketShareSection({dashboardData, selectedCasinos, timeframe, selectedChains}: {dashboardData: DashboardData | undefined, selectedCasinos: string[], timeframe: string, selectedChains: string[]}) {
  if (dashboardData === undefined) return <></>;

  const _data: any = {};

  const selectedData = dashboardData.entities.filter((entity) => selectedCasinos.includes(entity.id)).map(x => x.chains.find(y => y.chain === "AGG"));

  for (const chainData of selectedData) {
    if (!chainData) continue;
    //@ts-ignore
    const timeframeData = chainData[timeframe] as ChainTimeframeData;

    for (const transfer of timeframeData.transfers) {
      if (!_data[transfer.currencyName]) {
        _data[transfer.currencyName] = {
          currencyName: transfer.currencyName,
          iconUrl: dashboardData.tokenInfo.find(x => x.token === transfer.currencyName)?.iconUrl,
          deposits: 0,
          amountUsd: 0,
        };
      }

      _data[transfer.currencyName].deposits += transfer.deposits;
      _data[transfer.currencyName].amountUsd += transfer.amountUsd;
    }
  }

  const data = (Object.values(_data) as any[]).sort((a, b) => b.amountUsd - a.amountUsd);

  const totalDeposited = data.reduce((acc, groupedTransfers) => acc + groupedTransfers.amountUsd, 0);

  const aggregatedData = () => {
    if (!data || data.length <= 9) return data;
  
    const firstNine = data.slice(0, 9);
    const others = data.slice(9);
  
    const aggregatedOthers = others.reduce((acc, curr) => {
      acc.amountUsd += curr.amountUsd;
      acc.deposits += curr.deposits;
      return acc;
    }, { currencyName: "OTHER", amountUsd: 0, deposits: 0 });
  
    return [...firstNine, aggregatedOthers];
  };

  const shortData = aggregatedData();

  return (
    <div className={styles.marketShareSection}>
      <SectionHeader
        title={"Token Breakdown"}
        data={dashboardData}
        timeframe={timeframe}
        chains={selectedChains}
      />
      <div className={tableStyles.baseTableContainer}>
        <table className={tableStyles.baseTable}>
          <thead>
            <tr>
              <th style={{width: "5%"}} className={`${tableStyles.tableDataLeft} ${tableStyles.tableDataStart}`}>#</th>
              <th style={{width: "5%"}} className={`${tableStyles.tableDataLeft}`}>Currency</th>
              <th style={{width: "12%"}} className={`${tableStyles.tableDataRight}`}>Token Share</th>
              <th style={{width: "15%"}} className={`${tableStyles.tableDataRight}`}>Deposit Volume</th>
              <th style={{width: "12%"}} className={`${tableStyles.tableDataRight}`}>Deposits</th>
              <th style={{width: "20%"}} className={`${tableStyles.tableDataRight} ${tableStyles.tableDataEnd}`}>AVG Deposit</th>
            </tr>
          </thead>
          <tbody>
            { 
              shortData.map((groupedTransfers, index) => {

                const tokenShare = (groupedTransfers.amountUsd / totalDeposited * 100).toFixed(2) + "%"; // Round to 1 decimal place
                const deposited  = "$" + ToSmallNumber(groupedTransfers.amountUsd);
                const deposits = ToSmallNumber(groupedTransfers.deposits);
                const avgDeposit = "$" + ToSmallNumber(groupedTransfers.amountUsd / groupedTransfers.deposits);
  
                return (
                  <>
                    <tr className={tableStyles.rowGap}></tr>
                    <tr className={tableStyles.rowInfo} key={groupedTransfers.currencyName}>
                      <td className={`${tableStyles.tableDataLeft} ${tableStyles.tableDataStart}`}>{index + 1}</td>
                      <td className={`${tableStyles.tableDataLeft}`}>
                        <div className={tableStyles.casinoKey}>
                          {groupedTransfers.currencyName === "OTHER" ? <></> : <img style={groupedTransfers.iconUrl?.includes("coinmarketcap") ? {borderRadius: "100%"} : {}} src={groupedTransfers.iconUrl} alt={`Icon ${groupedTransfers.currencyName}`} /> }
                          <span>{groupedTransfers.currencyName}</span>
                        </div>
                      </td>
                      <td className={`${tableStyles.tableDataRight}`}>{tokenShare}</td>
                      <td className={`${tableStyles.tableDataRight}`}>{deposited}</td>
                      <td className={`${tableStyles.tableDataRight}`}>{deposits}</td>
                      <td className={`${tableStyles.tableDataRight} ${tableStyles.tableDataEnd}`}>{avgDeposit}</td>
                    </tr>
                  </>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MarketShareSection;
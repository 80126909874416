import { useMemo, useState } from "react";
import { FiSearch } from 'react-icons/fi'; // Import the search icon
import { Creator, CreatorData, Icons } from "../../models/CreatorsData";
import styles from './CreatorTable.module.scss';
import { Badge } from "../../components/Badge";
import { NavLink } from "react-router-dom";

function NameTag({num}: {num: number}) {

  if (num === 1) {
    return <div className={styles.tableTag}>Streaming</div> 
  } else if (num === 2) {
    return <div className={styles.tableTag2}>Raw</div> 
  } else {
    return <div className={styles.tableTag3}>Social Posts</div> 
  }
}

function CreatorTableRow({creator, icons, mediaProperty}: {creator: Creator, icons: Icons, mediaProperty: "creatorDeal" | "streamerDeal"}) {

  let creatorDeal = creator[mediaProperty];
  let creatorDeal2 = creator.deals.find(x => x.media === (mediaProperty === "creatorDeal" ? "Creator" : "Streamer") && x.type == "Current");

  if (creatorDeal === null || creatorDeal2 === null) return <></>;

  function TableRowGap() {
    return (
      <tr className={styles.tableGap}>
        <td colSpan={100}></td>
      </tr>
    );
  }

  function TableRow() {
    if (creatorDeal === null || creatorDeal2 === undefined) return <></>;

    return (
      <tr className={`${styles.tableRow}`}>
        <td className={`${styles.tableDataLeft} ${styles.tableDataStart}`}>{creatorDeal.rank}</td>
        <td className={styles.tableDataLeft}>
          <div className={styles.creatorContainer}>
            <img width="18px" height="18px" src={creator.pfp}/>
            <span>{creator.name}</span>
            {creatorDeal.verificationLevel > 0 && creatorDeal2.payments.filter(x => x.icon === "Raw").length > 0 ?
              <>
                <NameTag num={2}/>
              </> : <></>}
          </div>
        </td>

        {/* <td className={styles.tableDataCenter}>
        <div className={styles.tags}>
              {creator.verificationLevel > 0 ? 
              <>
                {creator.casino === "Rollbit" ? <><NameTag num={3}/></> : <><NameTag num={1}/> <NameTag num={2}/></>}
              </>: <></>}
              </div>       
              </td> */}

        <td className={`${styles.tableDataCenter}`}>
          <div className={`${styles.levelContainer}`}>
            <span className={styles.hideOnMobile}>Level</span>
            <div className={styles.badgeContainer}>
              <Badge level={creatorDeal.verificationLevel} fontSize={14} />
            </div>
          </div>
        </td>
        <td className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
          <div className={styles.casinoContainer}>
            <img width="18px" height="18px" src={icons[creatorDeal.casino] ?? ""}/>
            <span>{creatorDeal.casino}</span>
          </div>
        </td>
        <td className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
          <div className={`${styles.followersBox}`}>
               <img width="14px" height="14px" src={icons[creatorDeal.platform] ?? ""}/>
            {creatorDeal.followers?.toLocaleString() ?? "0"}
          </div>
        </td>
        {/* <td className={`${styles.tableDataCenter} ${styles.hideOnMobile} ${styles.streamLink}`}>
          <a href={creator.pageLink} target="_blank" rel="noopener noreferrer" className={`${styles.streamLink}`}>{creator.pageLink?.replace("https://", "").replace("www.", "") ?? "..."}</a>
        </td> */}
        <td className={`${styles.tableDataRight} ${styles.tableDataEnd}`}>
          <NavLink to={"/creators/" + creator.name.toLowerCase()}>
            <div className={`${styles.viewBox}`}>
              View
            </div>
          </NavLink>
        </td>
      </tr>
    );
  }

  return (
    <>
      <TableRowGap />
      <TableRow />
    </>
  );
}

export function CreatorTable({data, creatorType}: {data: CreatorData | undefined, creatorType: string}) {
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortProperty, setSortProperty] = useState('Score');
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;

  const [searchQuery, setSearchQuery] = useState(''); // 1. State for search query

  const mediaPropety: "creatorDeal" | "streamerDeal" = creatorType === "Creator" ? "creatorDeal": "streamerDeal";

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const sortData = (property: string) => {
    if (sortProperty === property) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortProperty(property);
      setSortOrder('desc');
    }
  };

  const SortIcon = ({property} : {property: string}) => {
    if (sortProperty === property) {
      return sortOrder === 'desc' 
        ? (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></g></svg>)
        : (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></g></svg>);
    }
    return (<svg fill="#fff" width="12px" height="12px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></g></svg>);
  }

  const SortableHeader = ({name} : {name: string}) => {
    return (
      <div>
        <div>
          <div style={{cursor: 'pointer'}} onClick={() => {sortData(name)}}>
            <SortIcon property={name}></SortIcon>
            {name === "Rank" ? <span className={styles.hideOnMobile} style={{marginLeft: "3px"}}>{name}</span> : <span style={{marginLeft: "3px"}}>{name}</span>}
          </div>

        </div>
      </div>
    )
  }

  const creatorsArray = data && data.creators ? Object.entries(data.creators) : [];

  // creatorsArray.sort(([keyA, a], [keyB, b]) => {
  //   if (b.verificationLevel !== a.verificationLevel) {
  //     return b.verificationLevel - a.verificationLevel; // Higher verificationLevel comes first
  //   } else {
  //     return b.followers - a.followers; // Higher followers comes first if verificationLevel is the same
  //   }
  // });

  // // Assign ranks to the sorted list
  // creatorsArray.forEach(([creatorKey, creator], index) => {
  //   creator = index + 1;
  // });


  const filteredCreators = useMemo(() => {
    let filtered = creatorsArray;
  
    // Filter by search query
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(([key, creator]) => {
        return (
          creator.name.toLowerCase().includes(lowerCaseQuery) ||
          creator[mediaPropety]?.casino.toLowerCase().includes(lowerCaseQuery) ||
          creator[mediaPropety]?.verificationLevel.toString().includes(lowerCaseQuery) ||
          creator[mediaPropety]?.followers.toString().includes(lowerCaseQuery)
        );
      });
    }

    filtered = filtered.filter(([key, creator]) => {
      return creator.deals.find(x => x.media === creatorType) !== undefined;
    });
  
    return filtered;
  }, [creatorsArray, searchQuery]);

  const sortedCreators = useMemo(() => {
    return filteredCreators.sort(([keyA, a], [keyB, b]) => {
      const aRelevantDeal = a[mediaPropety];
      const bRelevantDeal = b[mediaPropety];

      if (!aRelevantDeal || !bRelevantDeal) {
        console.log("relvant deal broke");
        return 1;
      }

      if (sortProperty === 'Rank') {
        return sortOrder === 'asc' ? aRelevantDeal.rank - bRelevantDeal.rank : bRelevantDeal.rank - aRelevantDeal.rank;
      } else if (sortProperty === 'Creator') {
        return sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (sortProperty === 'Verification Level') {
        if (a[mediaPropety]?.verificationLevel !== b[mediaPropety]?.verificationLevel) {
          return sortOrder === 'asc'
            ? aRelevantDeal.verificationLevel - bRelevantDeal.verificationLevel
            : bRelevantDeal.verificationLevel - aRelevantDeal.verificationLevel;
        } else {
          return bRelevantDeal.followers - aRelevantDeal.followers;
        }
      } else if (sortProperty === 'Casino') {
        const casinoComparison = aRelevantDeal.casino.localeCompare(bRelevantDeal.casino);
        if (casinoComparison !== 0) {
          return sortOrder === 'asc' ? casinoComparison : -casinoComparison;
        } else if (aRelevantDeal.verificationLevel !== bRelevantDeal.verificationLevel) {
          return bRelevantDeal.verificationLevel - aRelevantDeal.verificationLevel;
        } else {
          return bRelevantDeal.followers - aRelevantDeal.followers;
        }
      } else if (sortProperty === 'Followers') {
        return sortOrder === 'asc' ? aRelevantDeal.followers - bRelevantDeal.followers : bRelevantDeal.followers - aRelevantDeal.followers;
      } else {
        return aRelevantDeal.rank - bRelevantDeal.rank;
      }
    });
  }, [filteredCreators, sortOrder, sortProperty]);

  const totalResults = sortedCreators.length;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;

  const currentCreators = sortedCreators.slice(indexOfFirstResult, indexOfLastResult);

  return (
    <>
      {/* 7. Search Bar */}
      <div className={styles.controlsContainer}>
        {/* Search Bar */}
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder={`Search ${creatorType}...`}
            value={searchQuery}
            onChange={handleSearchChange}
            className={styles.searchInput}
            aria-label="Search creators"
          />
          <FiSearch className={styles.searchIcon} />
        </div>

        {/* Dropdown */}
        {/* <div className={styles.dropdownContainer}>
          <select
            value={selectedPlatform}
            onChange={(e) => {
              setSelectedPlatform(e.target.value);
              setCurrentPage(1); // Reset to first page on filter change
            }}
            className={styles.dropdown}
            aria-label="Filter by platform"
          >
            <option value="All">All Platforms</option>
            <option value="Kick">Kick Creators</option>
            <option value="X Creators">X Creators</option>
          </select>
        </div> */}
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.tableRow} ${styles.tableRowHeader}`}>
                <th style={{marginRight: "10px"}}className={`${styles.tableDataLeft} ${styles.tableDataStart}`}>
                  <SortableHeader name="Rank" />
                </th>
                <th className={`${styles.tableDataLeft}`}>
                  <SortableHeader name="Creator" />
                </th>
                {/* <th className={`${styles.tableDataCenter}`}>
                  Tags
                </th> */}
                <th style={{marginRight: "10px"}} className={`${styles.tableDataCenter}`}>
                Verification
                </th>
                <th className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
                  <SortableHeader name="Casino" />
                </th>
                <th className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
                  <SortableHeader name="Followers" />
                </th>
                {/* <th className={`${styles.tableDataCenter} ${styles.hideOnMobile}`}>
                  Page Link
                </th> */}

                <th className={`${styles.tableDataRight} ${styles.tableDataEnd}`}>
                  Profile
                </th>
              </tr>
          </thead>
          <tbody>
          {data && currentCreators
            .map(([key, datum], index) => {
              return <CreatorTableRow key={key} icons={data.icons} creator={datum} mediaProperty={mediaPropety}/>;
            })
          }
          </tbody>
        </table>
      </div>
      <div className={styles.tablePagination}>
        <div className={styles.paginationControls}>
          <span 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            className={currentPage !== 1 ? styles.pageAvailable : ""}
          >
            Previous
          </span>
          <span 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            className={currentPage !== totalPages ? styles.pageAvailable : ""}
          >
            Next
          </span>
        </div>
      </div>
    </>
    
  )
}
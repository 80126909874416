import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './styles/reset.scss';
import './styles/global.scss';
import Navbar from './components/Navbar';
import ContactPage from './pages/contact/Contact';
import AboutPage from './pages/about/AboutPage';
import AuditsPage from './pages/audits/AuditsPage';
import ScoreCalculatorPage from './pages/score-calculator/ScoreCalculatorPage';
import Footer from './components/Footer';
import AnalyticsPage from './pages/analytics/Analytics';
import CreatorsPage from './pages/creators/CreatorsPage';
import CreatorPage from './pages/creator/CreatorPage';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="page-container">
        <Navbar />
        <div className="page-content">
          <Routes>
            <Route path="/" element={<Navigate to="/creators" />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/audits" element={<AuditsPage />} />
            <Route path="/creators" element={<CreatorsPage />} />
            <Route path="/creators/:creatorId" element={<CreatorPage />} />
            <Route path="/score-calculator" element={<ScoreCalculatorPage />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

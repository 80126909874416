import styles from "./Badge.module.scss";

export function Badge({ level, fontSize }: { level: number, fontSize: number }) {
  const levelColors = [
    { start: "#989898", end: "#111525" }, // Level 0
    { start: "#00BD57", end: "#72F238" }, // Level 1 colors
    { start: "#E7DF29", end: "#E76202" }, // Level 2 colors
    { start: "#9747FF", end: "#FF76E9" }  // Level 3
  ];

  const gradientId = `gradient-${level}`;
  const { start, end } = levelColors[level] || levelColors[0]; // Use default for level > 2

  return (
    <div className={styles.badge}>
      <div className={styles.badgeText} style={{fontSize: fontSize + "px"}}>{level}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" viewBox="0 0 17 22" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 0.31C9.05 0.11 8.78 0 8.5 0C8.22 0 7.95 0.11 7.75 0.31C6.89 1.17 5.65 1.68 4.37 1.95C3.09 2.21 1.87 2.21 1.18 2.13C1.03 2.11 0.88 2.13 0.74 2.18C0.6 2.22 0.46 2.3 0.35 2.4C0.24 2.5 0.15 2.62 0.09 2.76C0.03 2.89 0 3.04 0 3.19V12.75C0 14.13 0.6 15.35 1.39 16.36C2.17 17.36 3.19 18.23 4.16 18.93C5.39 19.79 6.69 20.54 8.05 21.15L8.08 21.16H8.08C8.21 21.22 8.36 21.25 8.5 21.25C8.64 21.25 8.79 21.22 8.92 21.16L9.02 21.12C9.4 20.95 9.78 20.76 10.15 20.56C11.08 20.08 11.98 19.53 12.84 18.93C13.81 18.23 14.83 17.37 15.61 16.36C16.4 15.35 17 14.13 17 12.75V3.19C17 3.04 16.97 2.89 16.91 2.76C16.85 2.62 16.76 2.5 16.65 2.4C16.54 2.3 16.4 2.22 16.26 2.18C16.12 2.13 15.97 2.11 15.82 2.13C15.13 2.21 13.91 2.21 12.63 1.95C11.35 1.68 10.11 1.17 9.25 0.31Z"
          fill={`url(#${gradientId})`}
        />
        <defs>
          <linearGradient id={gradientId} x1="-5.31" y1="2.66" x2="22.84" y2="21.25" gradientUnits="userSpaceOnUse">
            <stop stopColor={start} />
            <stop offset="1" stopColor={end} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

import { ChainData, ChainTimeframeData, DashboardData, DataBlock_Clean, DataBlockAnalytics, GroupedTransfers_Clean } from "../../models/DashboardData";

function aggregateChainTimeframeData(dataArray: ChainTimeframeData[]): ChainTimeframeData {
  const aggregatedAnalytics: DataBlockAnalytics = {
    deposits: 0,
    uniqueDepositors: 0,
    depositedUsd: 0,
  };

  const aggregatedTransfers: { [currency: string]: GroupedTransfers_Clean } = {};

  const aggregatedDataBlocks: { [timestamp: number]: DataBlock_Clean } = {};

  dataArray.forEach(data => {
    // Aggregate top-level analytics
    aggregatedAnalytics.deposits += data.analytics.deposits;

    console.log("hi")
    console.log(aggregatedAnalytics.uniqueDepositors, data.analytics.uniqueDepositors)
    
    aggregatedAnalytics.uniqueDepositors += data.analytics.uniqueDepositors;

    console.log(aggregatedAnalytics.uniqueDepositors)

    aggregatedAnalytics.depositedUsd += data.analytics.depositedUsd;

    // Aggregate top-level transfers by currency
    data.transfers.forEach(transfer => {
      if (aggregatedTransfers[transfer.currencyName]) {
        aggregatedTransfers[transfer.currencyName].deposits += transfer.deposits;
        aggregatedTransfers[transfer.currencyName].amountUsd += transfer.amountUsd;
      } else {
        aggregatedTransfers[transfer.currencyName] = { ...transfer };
      }
    });

    // Aggregate data blocks by timestamp
    data.dataBlocks.forEach(block => {
      if (!aggregatedDataBlocks[block.timestamp]) {
        // If the timestamp doesn't exist, create a new entry
        aggregatedDataBlocks[block.timestamp] = {
          timestamp: block.timestamp,
          analytics: { ...block.analytics },
          transfers: block.transfers.map(transfer => ({ ...transfer })),
        };
      } else {
        // Aggregate analytics for the same timestamp
        aggregatedDataBlocks[block.timestamp].analytics.deposits += block.analytics.deposits;
        aggregatedDataBlocks[block.timestamp].analytics.uniqueDepositors += block.analytics.uniqueDepositors;
        aggregatedDataBlocks[block.timestamp].analytics.depositedUsd += block.analytics.depositedUsd;

        // Aggregate transfers for the same timestamp
        block.transfers.forEach(transfer => {
          const existingTransfer = aggregatedDataBlocks[block.timestamp].transfers.find(t => t.currencyName === transfer.currencyName);
          if (existingTransfer) {
            existingTransfer.deposits += transfer.deposits;
            existingTransfer.amountUsd += transfer.amountUsd;
          } else {
            aggregatedDataBlocks[block.timestamp].transfers.push({ ...transfer });
          }
        });
      }
    });
  });

  return {
    analytics: aggregatedAnalytics,
    transfers: Object.values(aggregatedTransfers),
    dataBlocks: Object.values(aggregatedDataBlocks),
  };
}

function FreshDataBlockAnalytics(): ChainTimeframeData {
  return { analytics: { deposits: 0, uniqueDepositors: 0, depositedUsd: 0 }, transfers: [], dataBlocks: [] }
}

export function AggregateDashboardData(data: DashboardData | undefined, selectedChains: string[]) {
  if (!data) return;

  console.log("aggregating")

  for (const entity of data.entities) {
    let newAggData: ChainData = {
      chain: "AGG",
      week: FreshDataBlockAnalytics(),
      month: FreshDataBlockAnalytics(),
      year: FreshDataBlockAnalytics(),
      addresses: []
    }
    
    entity.chains = entity.chains.filter(chain => chain.chain !== "AGG");

    for (const timeframe of ["week", "month", "year"]) {
      const toAggregate: ChainTimeframeData[] = [];

      for (const chain of entity.chains) {
        if (selectedChains.includes(chain.chain)) {
          //@ts-ignore
          toAggregate.push(chain[timeframe] as ChainTimeframeData);
        }
      }

      const aggregatedData = aggregateChainTimeframeData(toAggregate);
      //@ts-ignore
      newAggData[timeframe] = aggregatedData;
    }
    
    for (const chain of entity.chains) {
      newAggData.addresses = newAggData.addresses.concat(chain.addresses);
    }

    entity.chains.push(newAggData);
  }

  console.log("aggregated", data)
}

export function ToSmallNumber(num: number): string {
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(1)}B`;
  } else if (num >= 1e6) {
    return `${(num / 1e6).toFixed(1)}M`;
  } else if (num >= 1e3) {
    return `${(num / 1e3).toFixed(1)}K`;
  }
  return num.toFixed(1).replace(/\.0$/, "");
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};